.wrapper {
  margin: 0;
  padding: 20px 28px 10px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 55px;
  --table-height: calc(
    100vh - var(--header-height) - var(--topRow-height) - 180px
  );
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(246, 247, 250, 1);
  z-index: 99;
  text-align: left;
  padding: 28px;
  display: flex;
  justify-content: space-between;
  width: 83%;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    color: #3b3f43;
    margin: 8px 0px;
  }
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.topRow {
  height: var(--topRow-height);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.body {
  display: flex;
  flex-direction: column;
  > span {
    margin: 16px 0;
  }
}

.optionCard {
  > div {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 269px;
  height: 54px;
  margin: 0 0 0 16px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    width: 40px;
    height: 40px;
  }
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 12px;
  }
}

.table {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.actionIcon {
  cursor: pointer;
}

.searchInvoice {
  width: 378px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  > span {
    margin-right: 15px;
  }
}

.modal {
  display: flex;
}

.modalHeader {
  background: #fffcf1;
  padding: 16px 27px;
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #c9a11a;
  }
}

.modalBody {
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}
.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}

