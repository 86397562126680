.select {
  > div {
    height: 54px;
    &:hover {
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    }
    &:active {
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    }
    &:focus {
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    }
    > span {
      display: flex;
      align-items: center;
    }
  }
}

.disabled {
  > div {
    height: 54px;
    > span {
      display: flex;
      align-items: center;
    }
  }
}

.businessInfoSelect {
  max-width: 50vw !important;
  height: fit-content !important;
}

.default {
  > div {
    // height: 54px !important;
    height: fit-content !important;
  }
}

.small {
  > div {
    height: 44px !important;
  }
}
