.wrapper {
  margin: 0;
  padding: 20px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.searchATM {
  width: 378px;
  height: 54px;

  > div {
    background: #fbfbfb !important;
    border: 1px solid #fff !important;
    border-radius: 6px 6px 0 0 !important;
    box-shadow: none !important;
    transition: .3s border-color, .3s box-shadow;

    &:hover, &:active, &:focus {
      border: 1px solid #3F63E2 !important;
      border-radius: 6px !important;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11) !important;
    }
  }

  &:global(.ant-select-focused) > div {
    border: 1px solid #3F63E2 !important;
    border-radius: 6px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11) !important;
  }

  > span {
    margin-right: 15px;
  }

  & :global(.ant-select-selection-search-input) {
    border: 0;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.searchInput {
  box-shadow: none !important;
  & :global(.ant-input-prefix) {
    border-right: 0;
    color: #3B3F43;
    font-size: 20px;
    padding-right: 0;
  }
}

.bodyWrapper {
  display: flex;
  justify-content: space-between;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.atmLocatorCard {
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

.searchResultCard {
  border-radius: 0;
  background: transparent;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 23px 16px;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  > div > div:first-child {
    display: flex;
    flex-direction: column;
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #3b3f43;
    }
    span {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #3b3f43;
    }
  }
}

.searchResultsList {
  height: calc(100vh - var(--header-height) - 85px);
  overflow: auto;
  border-top: 1px solid #e5e7eb !important;

  > div {
    border-bottom: 1px solid #e5e7eb;
    cursor: pointer;
  }

  > div:hover {
    background-color: #f3f3ff;
  }

  &:global(.empty) > div{
    border-left: 0 !important;
    cursor: default !important;
  }

  & > div > :global(.selected){
    background-color: #f3f3ff;
    border-left: 3px solid #7372ff;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;

  &:global(.is-loading) {
    position: relative;
    background-color: #e5e3df;
    background-image:  linear-gradient(#dedede 2px, transparent 2px), linear-gradient(to right, #dedede 2px, #e5e3df 2px);
    background-size: 40px 40px;

    & > :not(:global(.ant-spin)) {
      opacity: .5;
      pointer-events: none;
    }

    & :global(.ant-spin) {
      position: absolute;
      z-index: 1;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }

  & :global(.gm-style) :global(.gm-style-iw-c) {
    overflow: visible !important;
    border-radius: 12px;

    &:before {
      content: '';
      width: 16px;
      height: 19px;
      border-width: 10px 8px 10px 8px;
      border-style: solid;
      border-color: transparent; 
      border-bottom-color: #3B3F43;
      position: absolute;
      top: 0;
      left: 0; 
      transform: translate(30px, -100%);
    }

    & :global(.gm-style-iw-d) {
      border-radius: 12px;
    }

    & > button {
      top: 0 !important;
      right: 0 !important;
      outline: 0;
    }

    & + :global(.gm-style-iw-tc) {
      display: none;
    }
  }
}

.toolTipCard {
  width: 280px;
  height: 70px;
  background-color: #3b3f43;
  color: #fff;
  padding: 0 0 12px 5px;
  display: flex;
  align-items: center;

  > div {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  > div > div {
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
    }
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
    }
  }
  > div > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  > div > div:last-child {
    display: flex;
    align-items: center;
    padding-top: 3px;
    padding-right: 15px;
  }
}

.searchBrowser {
  display: flex;
  flex-direction: column;
}

.searchingContainer {
  height: 100%;
  opacity: 0.2;
  margin: auto auto auto auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  > span {
    margin-left: 13px;
  }
}
