.container {
  // padding: 4.5rem 0 0;
  // padding: 2px 0 0;
  // margin: 0 0.5rem;


  // .greetingsSection {
  //     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  //     border-radius: 8px;
  //     background: rgba(255, 255, 255, 0.8);
  //     margin: 0.5rem;
  //     padding: 25px;
  //     font-size: 1.1rem;
  // }

  .postedStatus {
    background-color: #32620E40; // Background color for completed status
    color: #32620E; // Text color for completed status
    padding: 4px 8px; // Padding around the text
    border-radius: 4px; // Rounded corners
  }
  
  .pendingStatus {
    background-color: #FBF55540; // Background color for pending status
    color: #E8D314; // Text color for pending status
    padding: 4px 8px; // Padding around the text
    border-radius: 4px; // Rounded corners
  }

  .innerContent {
    padding: 2rem;
    height: 100%;
    background: white;
    border-radius: 8px;
    margin: 0 10px;
  }

  .greeting {
    h1 {
      margin-bottom: 20px; // Adjust as needed
    }
  }

  .heading {
    // color: #82898F;
    text-align: left;
    font-size: 1rem;
    margin-bottom: 20px; // Added margin for spacing
  }

  .totalBalanceCard, .bankCard, .statisticsCard, .actionCard {
    box-shadow: 0px 4px 4px 0px #0000000D;
    margin-bottom: 20px;
  }

  .totalBalanceCard {
    background-color: #F6F7FAB2;
    .balanceInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .amount {
        // font-size: large;
        font-size: 1.5rem;
        sup {
          font-size: smaller;
        }
      }
    }
  }

  .bankCard {
    // background: linear-gradient(90deg, rgba(212, 234, 247, 0.7) 0%, rgba(182, 204, 216, 0.7) 100%);
    border-style: solid !important;
    border-width: 1px;
    // border: black !important;
    box-shadow: 0px 4px 8px 4px #0000000D;
    cursor: pointer;

    @media screen and (max-width: 1500px) {
      .bankInfo {
        flex-direction: column;
        gap: 10px; /* Adjust as needed */
      }
    }

    @media screen and (max-width: 1500px) {
      .bankInfo {
        flex-direction: column;
        gap: 10px; /* Adjust as needed */
      }
    }

    .bankInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      .amount {
        font-size: 1.5rem;
      }
      div {
        &:first-child {
          text-align: left;
          div {
            &:first-child {
              font-weight: bold;
            }
            &:last-child {
              color: #82898F;
            }
          }
        }
        &:last-child {
          .amount {
            font-size: 1rem;
            sup {
              font-size: smaller;
            }
          }
        }
      }
    }
  }

  .bankCardUnSelect {
    // background: linear-gradient(90deg, rgba(212, 234, 247, 0.7) 0%, rgba(182, 204, 216, 0.7) 100%);
    // border-style: solid !important;
    // border-width: 1px;
    // border-color: wheat;
    // border: black !important;
    box-shadow: 0px 4px 8px 4px #0000000D;
    margin-bottom: 20px;
    
    cursor: pointer;

    @media screen and (max-width: 1500px) {
      .bankInfo {
        flex-direction: column;
        gap: 10px; /* Adjust as needed */
      }
    }

    @media screen and (max-width: 1500px) {
      .bankInfo {
        flex-direction: column;
        gap: 10px; /* Adjust as needed */
      }
    }

    .bankInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      .amount {
        font-size: 1.5rem;
      }
      div {
        &:first-child {
          text-align: left;
          div {
            &:first-child {
              font-weight: bold;
            }
            &:last-child {
              color: #82898F;
            }
          }
        }
        &:last-child {
          .amount {
            font-size: 1rem;
            sup {
              font-size: smaller;
            }
          }
        }
      }
    }
  }

  .statisticsCard {
    background-color: #F6F7FAB2;
    h3 {
      margin-bottom: 5px;
    }
    .chartContainer {
      max-width: 800px; // Adjust the size of the doughnut chart
      // margin: 0 auto;
      margin: 2rem;
      font-weight: bold !important;
    }
  }

  .actionCard {
    cursor: pointer;
    // display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    &:hover {
      background-color: #f0f0f0;
    }
  }

  .tableContainer {
    height: calc(100vh - 150px); // Adjust the height as needed
    overflow: auto;
  }
}

// Additional global styles or overrides can be added here
