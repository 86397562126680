@import "./colors.less";
@import "./fonts.less";

@body-background: #f6f7fa;
@border-radius-base: 6px;

@btn-default-color: @primary-color;
@btn-default-border: @primary-color;

@btn-disable-color: @disabled-color;
@btn-disable-bg: @disabled-bg;
@btn-disable-border: @disabled-bg;

@input-height-base: 54px;
@card-radius: 8px;
@card-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);

@radio-button-bg: #fff;
@radio-button-color: #9ba3ab;
@radio-button-hover-color: #c85103;
@radio-button-active-color: #c85103;

@table-header-bg: #fff;
@table-header-color: #9ba3ab;

@table-padding-vertical: 9px;
@table-font-size: 12px;

@dropdown-font-size: 12px;
@select-dropdown-font-size: 12px;

body {
  overflow-y: auto !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;

  &:focus,
  &:hover,
  &:active {
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }
}

// button

.ant-btn-circle {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ant-btn-round {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

// sidebar

.ant-menu-sub.ant-menu-inline {
  background: #f6f7fa;
  border: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: none;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  // left: 5px;
  // right: 17px;
}

// card

.ant-card-bordered {
  border: none;
}
//pagination
.ant-pagination-item {
  display: none !important;
}
// radio

.ant-radio-button-wrapper:first-child {
  border-left: none;
}
.ant-radio-button-wrapper {
  border: none;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #f3f3ff;
  color: #c85103;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background-color: #f3f3ff;
  color: #c85103;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: none;
}

.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  svg {
    display: block;
  }
}

// form

.ant-form-item {
  margin-bottom: 8px !important;
}

.ant-form-item-control {
  margin-bottom: 0px !important;
}

.ant-form-item-with-help .ant-form-item-explain {
  > div:not(:first-child) {
    display: none;
  }
}

// google maps

.gm-style .gm-style-iw-c {
  background: #3b3f43 !important;
  overflow: hidden !important;

  & + .gm-style-iw-tc:after {
    background: #3b3f43 !important;
  }
}

.gm-style-iw-d {
  background: #3b3f43 !important;
  overflow: hidden !important;
  padding-right: 12px;
}

.gm-ui-hover-effect {
  > span {
    background-color: #fff;
  }
}

.gm-style {
  font-family: "Inter", sans-serif !important;
  overflow: hidden !important;
}

.gm-style .gm-style-iw-t::after {
  background: #3b3f43 !important;
}
// sidebar

.ant-menu-item a {
  color: #9ba3ab;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #c85103;
}

// carousel

// .ant-carousel .slick-dots li.slick-active button {
//   background: #c85103;
// }

// .ant-carousel .slick-dots li button {
//   background: #9ba3ab;
//   height: 6px;
//   border-radius: 40px;
// }

.ant-carousel .slick-dots {
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
}

// input

.ant-input-prefix {
  color: #d1d5dd;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #d1d5dd;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0 5px;
}

// swiperjs



.swiper-pagination-bullet-active {
  background: #c85103 !important;
  width: 20px !important;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 12px;
  height: 6px;
  border-radius: 40px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  display: none;
}

#root
  > div
  > div.MainLayout_sectionWrapper__1wzr6
  > div
  > div
  > div
  > div.ManageCards_layout__2kayn
  > div
  > div.ManageCards_body__mqFMy
  > div
  > div:nth-child(1)
  > div
  > div.ManageCards_tableCardFooter__2J7f4
  > div
  > span {
  background: rgba(155, 163, 171, 1);
  margin-bottom: 6px;
}

// table

// .ant-table-thead
//   > tr
//   > th:not(:last-child):not(.ant-table-selection-column):not(
//     .ant-table-row-expand-icon-cell
//   ):not([colspan])::before {
//   content: none;
// }

.ant-table-thead > tr > th {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  border-color: rgb(80, 80, 80);
  // border: none;
  word-wrap: normal;
}
.ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #fbfbfb;
}
.ant-table-tbody > thead {
  background-color: #fff;
}
.ant-table-tbody > tr > td {
  // border: none;
  border-color: #d7d7d7;
  color: #3b3f43;
}

.ant-table table {
  // text-align: center;
  margin: 15px 0;
  border-color: #d7d7d7;
}
.ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #ffffff;
  font-size: 14px;
}
.ant-table-tbody > tr:nth-child(even) > td {
  font-size: 14px;
}

.ant-table-thead > tr > th {
  // text-align: center;
  color: #1D1C1C;
  // background: #F6F7FAB2;
  font-size: 14px;
  font-weight: bold;
  // cursor: pointer;
}
.ant-table-cell-row-hover {
  // background-color: #c85103 !important;
  color: #c85103 !important;
  cursor: pointer;
}

// stepper

// .ant-steps-dot .ant-steps-item-tail,
// .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
//   margin: 0;
// }

// .ant-steps-item-finish .ant-steps-item-icon {
//   display: none;
// }

// .ant-steps-item-process .ant-steps-item-icon {
//   display: none;
// }

// .ant-steps-item-wait .ant-steps-item-icon {
//   display: none;
// }

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  height: 3px;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  height: 3px;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  height: 3px;
}

.ant-steps-small .ant-steps-item-icon {
  visibility: hidden;
}

// modal

.ant-modal-footer {
  border-top: none;
  padding: 24px;
}

:root {
  --header-height: 99px;
}

// select

// .ant-select-single .ant-select-selector .ant-select-selection-item,
// .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
//   line-height: 12px !important;
// }

.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact
  > .ant-select:first-child
  > .ant-select-selector,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:first-child
  .ant-input {
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}

// collapse

.ant-collapse-borderless {
  background-color: transparent;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-collapse-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

// tabs

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #c85103;
}
// .ant-tabs-nav .ant-tabs-tab-active::after {
//   content: "";
//   position: absolute;
//   left: 43%;
//   bottom: -8px;
//   width: 19px;
//   height: 13px;
//   border-left: 20px solid transparent;
//   border-right: 20px solid transparent;
//   border-top: 20px solid #000;
//   clear: both;
//   z-index: 2147483647 !important;
// }

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  border: none;
  background-color: #fff;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 0;
  border-left: 1px solid #f0f0f0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0px;
}

// .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
// .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
//   box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
// }

.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  // flex: 1;
  width: 100%;
  > div {
    // flex: 1;
    width: 20%;
  }
  > div:first-child {
    border-top-left-radius: 8px;
  }
  > div:nth-last-child(2) {
    border-top-right-radius: 8px;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  > div > div > span {
    color: #fff !important;
  }
  > div > div {
    color: #fff !important;
  }
  > div > div > div {
    color: #fff !important;
  }
  text-shadow: none;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
}

[ant-click-animating-without-extra-node="true"]::after {
  display: none;
}

.lower-alpha {
  list-style-type: lower-alpha;
}

.upper-alpha {
  list-style-type: upper-alpha;
}

.lower-alpha {
  list-style-type: lower-alpha;
}

.none-list-type {
  list-style-type: none !important;
}

.link {
  text-decoration: underline;
}

.dashed-border {
  td,
  th {
    border: 1px dotted black !important;
  }
}

// New custom styles

.ant-form-item {
  margin-bottom: 20px !important;
}

.ant-form-item-label > label::after {
  content: '' !important;
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-modal-body {
  padding: 24px 24px 20px !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list > div, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list > div {
  width: fit-content;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 50px;
}

.ant-tree .ant-tree-treenode {
  padding: 0 0 20px 0;
  width: 100%;
}
.ant-tree-node-content-wrapper:hover {
  // background: #c85103 !important;
  color: #c85103 !important;
}

.ant-tree-node-content-wrapper {
  width: 100%;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #a8d9ff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #000000 !important;
  background: #2c0e0e00;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected:hover {
  color: #fff !important;
  background: #2c0e0e00;
}

.ant-tree-checkbox {
  position: absolute;
  // left: -20px;
  // top: 3px;
  right: 40px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000 !important;
}

#root {
  height: 100% !important;
}

.ant-notification-notice-message {
  font-size: 12px;
}

.App {
  height: 100% !important;
}

.ant-menu-item-selected a {
  color: #545454 !important;
}
.ant-menu-item a {
  color: #82898F;
}
.ant-menu-item a:hover{
  color: #545454 !important;
}

.ant-menu-title-content:hover {
  color: #545454 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #EEF1F1;
  box-shadow: 0px 4px 4px 0px #0000000D;
  border-radius: 8px;
  font-weight: 700;
}

// .ant-submenu:not(.ant-menu-horizontal) .ant-submenu-item-selected {
//   background: #EEF1F1;
//   box-shadow: 0px 4px 4px 0px #0000000D;
//   border-radius: 8px;
//   font-weight: 700;
// }

a {
  color: #c85103;
}

.ant-menu-item-active {
  background: #EEF1F1;
  box-shadow: 0px 4px 4px 0px #0000000D;
  border-radius: 8px;
  // font-weight: 700;
}

// // .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
// //   color: #545454;
// //   font-weight: 700;
// // }

.ant-menu-submenu-selected {
 color: #545454 !important;
}

.ant-menu-sub.ant-menu-inline {
  background: transparent;
}

.ant-menu-submenu-active {
  background: #EEF1F1;
  box-shadow: 0px 4px 4px 0px #0000000D;
  border-radius: 8px;
  // font-weight: 700;
  color: #545454 !important;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: none !important;
}

// .ant-menu-submenu-selected {
//   background: #EEF1F1;
//   box-shadow: 0px 4px 4px 0px #0000000D;
//   border-radius: 8px;
//   font-weight: 700;
//   color: #545454 !important;
// }

// .ant-menu-light {
//   color: #545454 !important;
// }

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #218737 !important;
}

// // .ant-steps-item-container {
// //   display: flex;
// //   flex-direction: column;
// //   align-items: center;
// //   gap: 1.5rem;
// // }

// .ant-steps-item-icon, .ant-steps-item-content {
//   text-align: center;
// }

// // .ant-steps-item-title::after {
// //   top: -40px;
// // }
// .ant-steps-item-title::after {
//   top: 25px;
// }

// .ant-steps-item-icon, .ant-steps-item-content {
//   vertical-align: middle;
//   margin-left: 10px;
// }

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: #218737;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: #218737;
  border-color: white;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white;
}

.ant-btn-default {
  border-color: black;
}

.ant-picker-range {
  border-radius: 8px 0 0 8px;
  border-color: #82898F;
  padding: 7px 25px;
}

.ant-picker-suffix {
  display: none;
}

// custom accordion styles
.ant-collapse-item {
  border: none;
  
  .ant-collapse-header {
    background-color: #f0f2f5;
    padding: 12px 20px;
    font-weight: bold;
    cursor: pointer;
    
    &:hover {
      background-color: #d9d9d9;
    }
  }
  
  .ant-collapse-content {
    border-top: 1px solid #d9d9d9;
    
    .ant-collapse-content-box {
      padding: 20px;
      
      .ant-collapse {
        margin-top: 20px;
      }
      
      .ant-table {
        margin-top: 20px;
        
        .ant-table-thead > tr > th {
          background-color: #f0f2f5;
          font-weight: bold;
        }
        
        .ant-table-tbody > tr > td {
          border: none;
        }
      }
      
      .ant-btn {
        margin-right: 10px;
      }
    }
  }
}

// Mobile Styles (up to 767px)
@media (max-width: 767px) {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 35%;
  }
}

// Tablet Styles (768px to 1023px)
@media (min-width: 768px) and (max-width: 1023px) {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 55%;
  }
}

// Medium Screens (1024px to 1279px)
@media (min-width: 1024px) and (max-width: 1279px) {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 70%;
  }
}

// Large Screens (1280px and above)
@media (min-width: 1280px) {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
  }
}

.ant-table-cell {
  word-wrap: normal;
}

.ant-radio-wrapper {
  width: fit-content !important;
}

.ant-tag {
  white-space: normal;
  text-align: left;
}

.ant-tag {
  white-space: normal;
  text-align: left;
  border-color: black;
  color: black;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.ant-popover {
  width: 400px !important;
  z-index: 100000;
}

.ant-popover-content {
  width: 400px !important;
  z-index: 100000;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: #008000 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #008000 !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #008000 !important;
}

label {
  margin: 0 !important;
  width: 50px !important;
}

.ant-form-item-label > label {
  margin: 0 !important;
  width: 200px !important;
}

// .ant-menu-item {
//   padding-left: 0 !important;
// }

// .ant-menu-submenu-title {
//   padding-left: 0 !important;
// }

// .ant-menu-submenu-title > .ant-menu-item {
//   padding-left: 50px !important;
// }

