.infoModal {
  .modalBody {
    overflow-y: auto;

    .modalBodyContent {
      background-color: rgba(#7372ff, 0.2);
      margin-right: 24px;
      padding: 15px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      h2 {
        color: rgba(#7372ff, 0.9);
        font-weight: 700;
      }

      p {
        font-size: 16px;

        ol {
          margin-top: 10px;
        }
      }
    }
  }

  .actionButton {
    display: flex;
    flex-direction: row-reverse;

    button {
      flex-basis: 150px;
    }
  }

  :global {
    .ant-modal-body {
      padding: 54px 0 24px 24px !important;
    }

    .ant-modal-footer {
      padding: 0 30px 24px 0 !important;
    }
  }
}
