.wrapper {
  margin: 0;
  padding: 20px 28px 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --tabHeader-height: 172px;
  --tabPane-height: calc(
    100vh - var(--header-height) - var(--tabHeader-height) - 95px
  );
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.filtersCard {
  margin: 0 16px;
  padding: 6px 8px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    padding: 0;
    margin: 0;
  }
}

.filtersGroup {
  button {
    margin-right: 0;
  }
}

.filterRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tabRadioButtonFocused {
  width: 122px;
  height: 35px;
  background-color: rgba(115, 114, 255, 0.2) !important;
  > span {
    color: #7372ff;
  }
}

.tabRadioButton {
  width: 122px;
  height: 35px;
  > span {
    color: #3b3f43;
  }
  > span:first-child > input[type="radio"]:checked {
    color: #fff;
    background-color: #7372ff;
  }
  input[type="radio"]:checked {
    color: #fff;
    background-color: #7372ff;
  }
}

.tabsWrapper {
  margin-top: 24px;
}

.tabsHeaderWrapper {
  > div > div > div > div {
    width: 100% !important;

    &:global(.ant-tabs-tab-active) :global(.ant-spin-dot-item)  {
      background-color: #fff!important;
    }
  }
}

.tabPaneWrapper {
  //   height: var(--tabPane-height);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
}
