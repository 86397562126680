.setupOtterzComponentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 0 0 15vh 0;
    box-sizing: border-box;
  
    .setupOtterzComponent {
      max-width: 400px;
      width: 100%;
      text-align: left;
  
      .businessInput {
        position: relative;
        margin: 15px 0 5px;
  
        .removeIcon {
          position: absolute;
          /* top: 50%; */
          right: -6px;
          transform: translateY(-50%);
          color: blue;
          cursor: pointer;
        }
      }
  
      .addBusinessButton {
        margin-bottom: 20px;
        float: right;
      }
  
      .submitButton {
        width: 100%;
      }
    }
  }
  
.heading {
    margin: 0;
}