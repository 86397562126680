.wrapper {
  margin: 0;
  padding: 20px 28px 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 130px;
  --secondRow-height: 0px;
  --table-height: calc(
    100vh - var(--header-height) - var(--topRow-height) -
      var(--secondRow-height) - 185px
  );
  --chart-height: calc(
    100vh - var(--header-height) - var(--topRow-height) -
      var(--secondRow-height) - 120px
  );
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.topRow {
  width: 100%;
  display: flex;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    width: 100%;
  }
}

.cashbackCard {
  // max-height: var(--topRow-max-height);
  height: var(--topRow-height);
  width: 100%;
  margin-bottom: 8px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  color: #3b3f43;
}

.totalCashback {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
  .amount {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 36px;
      margin-left: 24px;
      margin-right: 5px;
    }
  }
}

.previousPayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid #f0f0f0;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 58px;
  }
  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 10%;
    width: 45%;
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 10%;

    > span:first-child {
      color: #1dbc83 !important;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 24px;
      color: #3b3f43;
      margin-bottom: 8px;
      display: flex;
      align-items: flex-start;
      > span {
        color: #1dbc83 !important;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 19px;
        text-align: right;
        margin-right: 5px;
      }
    }
  }
  .icon {
    background-color: #e1fff4;
    color: #1dbc83;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
  }
}

.upcomingPayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid #f0f0f0;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  h3 {
    color: #7372ff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 58px;
  }
  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 10%;
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 10%;
    > span:first-child {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #3b3f43;
      margin-bottom: 8px;
      display: flex;
      align-items: flex-start;
      > span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        text-align: right;
        margin-right: 5px;
      }
    }
    > span:last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #9ba3ab;
    }
  }
  .icon {
    background-color: #f3f3ff;
    color: #7372ff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
  }
}

.refreshBtn {
  background-color: #f3f3ff !important;
  color: #7372ff !important;

  span {
    margin-left: 5px !important;
    margin-top: 5px !important;
  }

  &:active {
    border: none !important;
  }
  &:focus {
    border: none !important;
  }
}

.topicIconBackdrop {
  position: absolute;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  left: 35px;
  top: 40px;
  background: #f3f3ff;
}

.cashbackPercentageCard {
  margin: 8px 8px 8px 0;
  > div {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // max-height: var(--secondRow-max-height);
    height: var(--secondRow-height);
    margin: 0;
    padding: 28px 25px;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  > div > div:first-child {
    display: flex;
    align-items: center;
    span {
      width: 180px;
      margin-left: 40px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #3b3f43;
    }
  }
  > div > div:last-child {
    display: flex;
    align-items: flex-start;
    > span:first-child {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #1dbc83;
      margin-right: 5px;
    }
    > span:last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 36px;
      color: #1dbc83;
    }
  }
}

.totalAccuCashback {
  margin: 8px 0 8px 8px;
  > div {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // max-height: var(--secondRow-max-height);
    height: var(--secondRow-height);
    margin: 0;
    padding: 28px 25px;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  > div > div:first-child {
    width: 180px;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3b3f43;
  }
  > div > div:last-child {
    display: flex;
    align-items: flex-start;
    > span:first-child {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 36px;
      color: #3b3f43;
      margin-right: 5px;
    }
    > span:last-child {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #3b3f43;
    }
  }
}

.tableCardLeft {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 16px 8px 0 0;
}

.tableCardRight {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 16px 0 0 0;
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 5px 24px;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tableCardFilters {
  display: flex;
  padding: 5px 24px 16px 24px;
}

.tableCardBody {
  padding: 20px;
  width: 98%;
  height: var(--chart-height);
}

.table {
  height: var(--table-height);
}

.searchReward {
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  width: 378px;

  > span {
    margin-right: 15px;
  }
}

.rewardFilters {
  display: flex;
  flex-direction: row;
}

.filtersCard {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  margin: 0 16px;
  padding: 10px 14px;
  height: 54px;

  > div {
    padding: 0;
    margin: 0;
  }
}

.filtersGroup {
  button {
    margin-right: 0;
  }
}

.actionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
}

.actionCard {
  height: 37px;
  margin-right: 16px;

  > div {
    padding: 0;
    margin: 0;
  }

  button {
    display: flex;
    align-items: center;
    top: 3px;
  }
}

.filterButtons {
  display: flex;
  flex-direction: row;
}

.dropdownFilter {
  display: flex;
  align-items: center;
  color: #3b3f43;

  &:hover {
    color: #000;
  }

  &:focus {
    color: #000;
  }

  &:active {
    color: #000;
  }
}

.spinnerContainer {
  > div {
    > div {
      display: block;
      margin: auto;
      margin-top: 200px;
    }
  }
}

.closeIcon {
  cursor: pointer;
  color: crimson;
  padding-top: 5px;
}

.tabRadioButton {
  height: 36px;

  > span {
    color: #3b3f43;
  }

  > span {
    &:first-child {
      > input[type="radio"] {
        &:checked {
          color: #fff;
          background-color: #7372ff;
        }
      }
    }
  }

  input[type="radio"] {
    &:checked {
      color: #fff;
      background-color: #7372ff;
    }
  }
}

.tabRadioButtonFocused {
  height: 36px;
  background-color: rgba(115, 114, 255, 0.2) !important;

  > span {
    color: #7372ff;
  }
}

.transactionStatusFilters {
  margin: 1px 16px 0 16px;
}
