.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0,0,0,0.45);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}