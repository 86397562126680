.wrapper {
  margin: 0;
  padding-top: 100px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.subHead {
    line-height: 2.5;
    color: gray;
}

.headerWrapper {
  text-align: left;
  margin: 30px 0 20px 0;
  width: 416px;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #3b3f43;
    margin: 8px 0px;
  }
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #9ba3ab;
  }
}

.steps {
  margin: 46px auto;
}

.formWrapper {
  margin: 10px auto 40px auto;
  width: 52%;
}

.linksWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 32px 0;
}

.links {
  color: #9ba3ab;
}

// .backButtonWrapper {
//   position: absolute;
//   background-color: #fff;
//   z-index: 999;
//   top: 0;
//   width: 52%;
//   padding-top: 79px;
//   margin-right: 6px;
// }

.wireAuthForm {
  text-align: center !important;
}

.stepperBackButton {
  margin-top: 20px;
  margin-left: 24%;
}

.mobileInputPrefix {
  margin-top: 8px;
  > div {
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
  }
}

.stepsWrapper {
  background: #fff;
  padding-top: 26px;
  padding-bottom: 20px;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 42%;
  z-index: 99;
  // width: 100%;
}

.stepper {
  width: 52%;
  margin: 0 auto;
  // position: relative;
}

.step {
  // width: calc(100% / 8);
  margin-left: -42px;
}

.stepperBackButton {
  margin-top: 20px;
  margin-left: 24%;
}
