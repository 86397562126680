.container {
  // padding: 4.5rem 0 0;
  padding: 2px 0 0;

  .greetingsSection {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    margin: 0.5rem;
    padding: 25px;
    font-size: 1.1rem;
  }

  .postedStatus {
    background-color: #32620e40; // Background color for completed status
    color: #32620e; // Text color for completed status
    padding: 4px 8px; // Padding around the text
    border-radius: 4px; // Rounded corners
  }

  .pendingStatus {
    background-color: #fbf55540; // Background color for pending status
    color: #e8d314; // Text color for pending status
    padding: 4px 8px; // Padding around the text
    border-radius: 4px; // Rounded corners
  }

  .innerContent {
    padding: 2rem;
    min-height: 100vh;
    background: white;
    border-radius: 8px;
    margin: 0.5rem;
    background-color: white;
  }

  .bankCard {
    height: fit-content;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    background-color: white;
    border: 1px solid black;
  }

  .ant-typography-disabled {
    color: #82898F;
  }

  .column {
    flex: 1;
    justify-content: "center";
    display: "flex";
    flex-direction: "column";
    margin-right: 16;
  }

  .tableContainer {
    height: calc(100vh - 150px); // Adjust the height as needed
    overflow: auto;
  }

  :global(.ant-tree-title) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  :global(.ant-tree-treenode) {
    padding: 0;
  }

  .saveButton {
    background-color: white;
    color: #000000;
  }

  .treeNodeMove {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;

    :global(.ant-tree-title) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .ant-tree {
      width: 100% !important;
    }
  }
}

// Additional global styles or overrides can be added here
