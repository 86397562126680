.message {
  color: rgba(0, 0, 0, 0.85);
  > div {
    display: inline-flex;
    justify-content: flex-start;
  }
  svg {
    color: rgba(0, 0, 0, 0.5);
  }
}

.success {
  > div {
    background: #8de89b;
  }
}

.info {
  > div {
    background: #69c0ff;
  }
}

.warning {
  > div {
    background: #ffe58f;
  }
}

.error {
  > div {
    background: #ffccc7;
  }
  svg {
    color: #ee6969 !important;
  }
}
