// UploadTaxReturns.module.scss

.profileComponent {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
}

.profileComponent p {
  font-size: 16px;
  margin-bottom: 10px;
}

.uploadContainer {
  margin-top: 20px;
}

.taxUpload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem;
  flex-direction: column;
}

.uploadContainer .ant-upload.ant-upload-select-picture-card {
  width: 100%;
}

.uploadContainer .ant-upload-list-item {
  display: flex;
  align-items: center;
}

.uploadContainer .ant-upload-list-item-name {
  flex: 1;
}

.uploadContainer .ant-upload-list-item-thumbnail {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.ctaButton {
  margin-top: 20px;
}

.ctaButton .ant-btn {
  width: 100%;
}

.radioGroup {
  width: 100%;
}
