.wrapper {
  margin: 0;
  padding: 20px 28px 10px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 60px;
  --datePickerRow-height: 50px;
  --table-height: calc(
    100vh - var(--header-height) - var(--topRow-height) - 195px
  );
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(246, 247, 250, 1);
  z-index: 99;
  text-align: left;
  padding: 28px;
  display: flex;
  justify-content: space-between;
  width: 83%;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    color: #3b3f43;
    margin: 8px 0px;
  }
}

.headerName {
  display: flex;
  align-items: center;
  h2 {
    margin-left: 15px;
  }
}

.buttonWrapper {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.topRow {
  width: 100%;
  height: var(--topRow-height);
  display: flex;
  justify-content: space-between;
}

.datePickerRow {
  width: 100%;
  height: var(--datePickerRow-height);
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
}

.typeModeStateRow {
  width: 100%;
  height: var(--datePickerRow-height);
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  > button {
    height: var(--datePickerRow-height);
    margin-right: 12px;
  }
  > div {
    height: var(--datePickerRow-height);
    margin-right: 12px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  > span {
    margin: 16px 0;
  }
}

.optionCard {
  cursor: pointer;
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 301px;
  height: 54px;
  margin: 0 0 0 16px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    width: 40px;
    height: 40px;
  }
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 0 12px;
  }
}

.table {
  width: 100%;
  overflow: hidden;
  tbody > tr:hover > td {
    cursor: pointer;
  }
}

.actionIcon {
  cursor: pointer;
}

.search {
  // width: 600px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
}

.filterSelect {
  width: 173px;
  border-radius: 8px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
}

.filterInput {
  border-top-width: 0px;
  border-right-width: 0px !important;
  border-bottom-width: 0px;
}

.filterDatePicker {
  border-top-width: 0px;
  border-right-width: 0px !important;
  border-bottom-width: 0px;
}

.transactionFilters {
  align-items: center;
  
  > span {
    display: flex !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3b3f43;
  }
}

.filtersCard {
  margin-left: 16px;
  padding: 10px 14px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    padding: 0;
    margin: 0;
  }
}

.filtersGroup {
  button {
    margin-right: 5px;
  }
}

.optionTxtButton {
  margin-right: 5px;
  padding: 0.5px;
  // border: 1px solid #d1d5dd;
  &:hover {
    color: #7372ff;
  }
  &:active {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
  &:focus {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
}
.salesItemSelect {
  > div {
    border-radius: 0 !important;
  }
}

.paymentMedium {
  display: flex;
  gap: 5px;
  align-items: center;

  > div {
    height: 38px;

    img {
      width: 41px;
      height: 100%;
    }
  }
}

div.cardImage {
  height: auto;
  
  img {
    width: 41px;
    height: auto;
  }
}
