.wrapper {
  margin: 0;
  width: 100%;
  // height: 100%;
  // display: flex;
  // justify-content: space-between;
}


.sectionWrapper {
  // width: 83vw;
  height: 100%;
  // width: 100%;
  // position: absolute;
  // overflow-y: scroll;
  // top: 0;
  // right: 0;
  background-color: #F6F7FA;
}

.setNotificationsModalHeader {
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3b3f43;
  }
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #9ba3ab;
  }
}

.setNotificationsModalTableHeader {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
  //font-weight: 500;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 70px;
    > span {
      margin-top: 11px;
    }
  }
}
.notificationForm {
  font-weight: 500;
}

.setNotificationsModalBodyItem {
  border-top: 1px solid #e5e7eb;
  padding: 3px 0 3px 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.setNotificationsModalCheckboxes {
  display: inline-flex;
  justify-content: flex-end;
  width: 98.3%;
}

.setNotificationsModalCheckboxesLastRow {
  display: inline-flex;
  justify-content: flex-end;
  width: 67.3%;
}

.setNotificationsModalFooterCTA {
  border-top: 1px solid #e5e7eb;
  > div > div > div {
    display: flex;
    justify-content: flex-end;
  }
}

.modal {
  > div > div {
    overflow-y: auto;
  }
}

.arrowBtnWithBg {
  color: #fff;
  background-color: #7372ff;
  border-radius: 50%;
  cursor: pointer;
}

.menuItem {
  background: #f6f7fa;
  border-radius: 8px;
  padding: 24px 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3b3f43;
    > div {
      margin-left: 16px;
    }
    > div:last-child {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #82898f;
    }
  }
}

.formWrapper {
  margin: 10px auto 40px auto;
  width: 52%;
}

.links {
  margin: 28px auto;
  text-align: center;
  > span {
    color: #9ba3ab;
  }
}

.otpWrapper {
  width: 52%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otp {
  input {
    padding-left: 21px;
    margin-right: 8px;
    width: 54px;
  }
}

.options {
  display: flex;
  justify-content: space-between;
  p {
    color: #3f63e2;
  }
}

.otpSignIn {
  height: 120px;
  padding: 16px 20px 16px 16px;
  background-color: #fcfdff;
  div {
    font-size: 14px;
    font-weight: 600;
    color: #3b3f43;
  }
  p {
    height: 66px;
    color: #9ba3ab;
    font-weight: 400;
    font-size: 14px;
    padding: 0 24px 16px 28px;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: row;
}

.heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #82898f;
  margin-bottom: 40px;
}

.mobileInputPrefix {
  > div {
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
  }
}
