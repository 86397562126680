.wrapper {
  margin: 0;
  flex: 1;
  // display: flex;
  // justify-content: space-between;
}


.sectionWrapper {
  // width: 83vw;
  // position: absolute;
  // top: 0;
  // right: 0;
  // padding: 0 0.5rem;
  background-color: #F6F7FA;
}
