.greetingsSection {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    margin: 0.5rem;
    padding: 25px;
    font-size: 1.1rem;
}

.userManagement {
  // margin: 20px;
  margin: 0px;

  h1 {
    margin: 4rem 0 1rem;
  }

  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }

  .actionIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    button {
      margin-left: 8px;
    }
  }
}

.inviteButton {
  background: linear-gradient(92.79deg, #7372FF 8.36%, #9772FF 92.75%);
  width: 183.45px;
  height: 44px;
  border-radius: 8px;
  float: right;
}

label {
  margin: 0 !important;
  width: 200px !important;
  text-align: left;
}

h1 {
  margin: 4rem 0 3rem;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.table {
  // width: 100%;
  // border-collapse: collapse;
  width: 72vw;
  // border-collapse: collapse;

  th, td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }

  th {
    // background-color: #f2f2f2;
  }
}

.extendedSection {
  // margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;

  .extendedCard {
    border: 1px solid #EEF1F1 !important;
    box-shadow: 0px 4px 4px 0px #0000000D !important;
    height: 100% !important; // Adjust height as needed
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 150px;

    .cardHeading {
      font-weight: bold;
      color: #82898F;
      padding: 10px;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 1500px) {
      .cardHeading {
        font-size: 0.96rem;
        padding: 10px 10px 0 10px;
      }
    }

    p {
      margin: 5px 0;
    }

    .flexRow {
      // display: flex;
      width: 100%;

      div {
        display: flex;
        // text-align: left;
        text-align: center;
        justify-content: space-evenly;
        // flex-direction: column;
        // align-items: flex-start;
      }
    }

    @media screen and (max-width: 1100px) {
      .flexRow {
        font-size: 0.7rem;
      }
    }

    .upgradePlan {
      .ant-card-body {
        padding: 0 !important;
      }
    }

    .ctaButton {
      margin-top: 10px;
      box-shadow: 0px 4px 4px 0px #0000000D;
    }
  }
}

.rolesPermissions {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  // height: 100vh;
  padding: 0;

  .profileForm {
    margin-top: 20px;
    width: 50% !important;
    padding: 1rem;
    background: white;

    .formLayout {
      display: flex;
      justify-content: space-between;

      .leftColumn, .rightColumn {
        flex: 1;
      }

      .leftColumn {
        // margin-right: 20px;
      }
    }

    .saveButton {
      float: right;
      margin-top: 20px;
      width: 185px;
      padding: 0 30px;
    }

    .cancelButton {
      margin-right: 8px; // Space between Cancel and Save Changes buttons
      // float: right;
      margin-top: 20px;
      width: 170px;
      background: white;
      color: #000000;
      padding: 0 30px;
    }

    :global(.ant-form-item-label) {      
      margin-right: 5vw !important;
    }
  }

  .columnTitles {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 20vw;
  }

  .roleTitle, .permissionsTitle {
    font-weight: bold;
  }

  .separator {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }

  .content {
    display: flex;
    height: 100%;

    .rolesColumn, .permissionsColumn {
      flex: 1;
    }

    .verticalSeparator {
      width: 1px;
      background-color: #ccc;
      margin: 0 20px;
    }

    .rolesColumn {
      .ant-tabs-tabpane {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.ant-popover {
  width: 400px;
}

.content {
  :global(.ant-tabs-nav-list) {
    margin: 0 2rem 0 0 !important;
    width: 20vw !important;
  }
  
  :global(.ant-tabs-ink-bar) {
    width: 100% !important;
    z-index: -1 !important;
    border-radius: 2px !important;
    border: 0.5px solid #7372FF !important;
    background: rgba(246, 247, 250, 0.70) !important;
  }
}
