.wrapper {
  margin: 0;
  padding: 28px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  justify-content: space-between;
}

.body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.layoutLeft {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.layoutRight {
  display: flex;
  flex-direction: column;
  width: 40vw;
}

.tableCard {
  > div {
    padding: 0;
    margin: 0;
  }
  margin-bottom: 16px;
  width: 55vw;
}

.tableCardBody {
  margin: 5px 24px 12px 24px;
}

.tableCardFilters {
  display: flex;
  padding: 5px 24px 10px 24px;
}
.tableCardFilters[disabled] {
  opacity: 0;
  cursor: pointer;
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 5px 24px;
  > div:first-child {
    display: flex;
    align-items: center;
  }
}
.otp {
  input {
    margin: auto;
    height: 48px !important;
    width: 48px !important;
  }
}

.optionCardCTA {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 100%;
  margin-bottom: 8px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
}
.optionCard[disabled] {
  transition: 0.3s;
  color: #f3f3ff;
  pointer-events: none;
  opacity: 0.5;
  .cardAction {
    .arrowBtnWithBg {
      color: #fff;
      background-color: #f3f3ff;
    }
  }
}

.optionCard {
  transition: 0.3s;
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 100%;
  height: 97px;
  margin: 8px 0;
  background: #fff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .cardAction {
    color: #3b3f43;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    > div:first-child {
      display: flex;
      align-items: center;
      > span:last-child {
        margin-left: 42px;
      }
    }
    .optionIcon {
      z-index: 1;
    }
    .optionIconBackdropLight {
      position: absolute;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      left: 35px;
      top: 45px;
      background: #f3f3ff;
    }
    .arrowBtnWithBg {
      color: #fff;
      background-color: #7372ff;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.stolenCard {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 100%;
  height: 97px;
  margin: 8px 0;
  background: #fff8f8;
  border: 1px solid #ee6969;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .cardAction {
    color: #ee6969;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    > div:first-child {
      display: flex;
      align-items: center;
      > span:last-child {
        margin-left: 42px;
      }
    }
    .optionIcon {
      z-index: 1;
    }
    .optionIconBackdropLight {
      position: absolute;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      left: 35px;
      top: 45px;
      background: #f3f3ff;
    }
    .arrowBtnWithBg {
      color: #fff;
      background-color: #ee6969;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.stolenCard[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.creditCardWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  > div {
    margin: 8px 0;
  }
}

.creditCard {
  width: 378px;
  height: 236px;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.3);
  border-radius: 0px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 26px 25px;
    margin: 0;
  }
  .creditCardNumber {
    margin: 10px 0;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 31px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  }
}

.creditCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cardType {
    border-radius: 11px 0px 11px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    text-transform: uppercase;
    width: 110px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
  }
}

.creditCardInfo {
  display: inline-flex;
  align-items: center;
  gap: 40px;
  > div {
    display: flex;
    // align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    > span:first-child {
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 10px;
      margin-bottom: 4px;
    }
    > span:last-child {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 17px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.creditCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
  background-color: #7372ff;
  width: 378px;
  position: absolute;
  top: 233px;
  left: 0px;
  height: 33px;
  z-index: 1;
  color: #fff;
  margin-top: 8px;
  .creditCardFooterAction {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    > span {
      margin-left: 9px;
    }
  }
  .viewCard {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
  }
}

.carouselItemCreditCard {
  width: 100%;
  height: 100%;
  > div {
    margin: 40px auto;
  }
}

.carouselItemDetails {
  display: flex;
  justify-content: space-between;
  margin: 38px 24px 15px 24px;
  > div:first-child {
    display: flex;
    flex-direction: column;
    > span:first-child {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: #3b3f43;
    }
    > span:last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #9ba3ab;
    }
  }
  > div:last-child {
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    color: #ffffff;
    > span {
      padding: 6px 10px;
      border-radius: 48px;
    }
  }
}

.carouselItemUsageDetails {
  margin: 10px 24px;
}

.carouselItemFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 24px;
  .carouselItemNotDefine {
    display: flex;
    flex-direction: column;
    font-size: 14px !important;
  }
  > div {
    display: flex;
    > span:first-child {
      font-style: normal;
      font-weight: bold;
      font-size: 39px;
      line-height: 47px;
      color: #3b3f43;
      margin-right: 16px;
    }
    > span:last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      display: grid;
      align-items: center;
      color: #3b3f43;
      width: 60px;
    }
  }
  button {
    margin: 0;
    padding: 0;
  }
}

.tableCardFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e5e7eb;
  height: 54px;
  > span {
    margin: 16px 0 24px 0;
  }
}

.carouselItemWrapper {
  margin: 0;
}

.carouselDot {
  width: 12px;
  height: 6px;
  margin: 0 4px;
  background: rgba(155, 163, 171, 0.35);
  border-radius: 40px;
  border: none;
  &:hover {
    background-color: #7372ff !important;
  }
  &:active {
    background-color: #7372ff !important;
    border: none !important;
    // width: 20px !important;
  }
  &:focus {
    background-color: #7372ff !important;
    border: none !important;
    // width: 20px !important;
  }
}

.modal {
  display: flex;
}

.modalHeader {
  background: #fffcf1;
  padding: 16px 27px;
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #c9a11a;
  }
}

.modalBody {
  margin: 0;
  min-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
}

.modalLeft {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 60%;
}

.modalRight {
  border-left: 1px solid #f0f0f0;
  padding: 24px;
  overflow: hidden;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}

.setNotificationsModalHeader {
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3b3f43;
  }
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #9ba3ab;
  }
}

.setNotificationsModalTableHeader {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 45px;
    > span {
      margin-top: 11px;
    }
  }
}

.setNotificationsModalBodyItem {
  border-top: 1px solid #e5e7eb;
  padding: 14px 0 8px 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.setNotificationsModalCheckboxes {
  display: inline-flex;
  justify-content: flex-end;
  width: 98.3%;
}

.setNotificationsModalCheckboxesLastRow {
  display: inline-flex;
  justify-content: flex-end;
  width: 67.3%;
}

.setNotificationsModalFooterCTA {
  border-top: 1px solid #e5e7eb;
  > div > div > div {
    display: flex;
    justify-content: flex-end;
  }
}

.otpWrapper {
  display: flex;
  flex-direction: column;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #3b3f43;
  }
  > span:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
  }
}

.otp {
  input {
    padding-left: 21px;
    width: 54px;
  }
}

.otpForm {
  margin: 24px 0 0 0;
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
    color: #9ba3ab;
  }
}

.linkBtn {
  margin: 0;
  padding: 0;
  text-decoration: underline;
  font-size: 12px;
}

.optionTxtButton {
  height: 44px !important;
  margin-right: 20px;
  border: 2px solid #d1d5dd !important;
  &:hover {
    color: #7372ff;
    background-color: #bdbdf8;
  }
  &:active {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
  &:focus {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
}

.overflowModalBody {
  margin: 0;
  padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;
}

.radialChartInner {
  position: absolute;
  top: 135px;
  left: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > span:first-child {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #9ba3ab;
  }
  > span:last-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3b3f43;
  }
}

.categoryWiseSpendWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spentCategoriesList {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
}

.spentCategory {
  display: flex;
  margin: 6px 0;
  padding: 8px;
  border: 1px solid transparent;
  &[data-active="true"] {
    background: #f3f3ff;
    border: 1px solid #7372ff;
    border-radius: 8px;
  }
  > span {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 0 8px 0 0;
  }
  > div {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 33%;
    width: 105px;
  }
  > div > span:first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #9ba3ab;
  }
  > div > span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
  }
}

.vgsBtn {
  padding: 4px;
  width: 385px;
  height: 44px;
  background: #7372ff;
  color: #fff;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.vgsGroup {
  display: flex;
  height: 110px;
  overflow: hidden;
  > label:first-child > div {
    width: 285px;
  }
  > label:last-child > div {
    width: 100px;
  }
}

.vgsGroupChangePin {
  display: flex;
  height: 110px;
  overflow: hidden;
  > label > div {
    width: 192.5px;
  }
}

.vgsGroupSetPin {
  display: flex;
  height: 110px;
  margin-left: 20px;
  overflow: hidden;
  > label > div {
    width: auto;
  }
}
.radioContainer {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0;
  .radioButton {
    margin: 15px 0 0 20px;
  }
}
.noCardsContainer {
  margin: 40px 60px !important;
}
.textContainer {
  margin: 20px 0px;
  display: inline-block;
  height: 50px;
  > div {
    font-weight: 700;
    font-size: 16px;
  }
  .bottomRow {
    font-size: 14px;
    color: #82898f;
    font-weight: 400;
  }
}
.categoryWiseSpendEmptyWrapperWrapper {
  display: block;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 90px;
}

.categoryWiseSpendEmptyWrapper {
  display: block;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 20px;
}

.spendingAllCards {
  opacity: 0 m !important;
  cursor: pointer;
  background-color: #7372ff !important;
  font-size: 1000px;
}

.activateCard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 500px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.notification {
  height: 40px;
}
