// @use "sass:math";

// @function strip-unit($number) {
//   @if type-of($number) == "number" and not unitless($number) {
//     @return $number / ($number * 0 + 1);
//   }

//   @return $number;
// }

.wrapper {
  margin: 0;
  padding: 20px 28px 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --balanceCard-height: 92px;
  --fundTransferForm-height: calc(100vh - var(--header-height) - 445px);
  // --chart-height: calc(
  //   100vh - var(--header-height) - var(--balanceCard-height) - 425px
  // );
  --chart-height: 28vh;
  --table-height: calc(
    100vh - var(--header-height) - var(--balanceCard-height) -
      var(--chart-height) - 234px
  );
  // --chart-height: math.div(
  //   calc(
  //     100vh - var(--header-height) - var(--balanceCard-height) -
  //       var(--table-height) - 100px
  //   ),
  //   (
  //     calc(
  //         100vh - var(--header-height) - var(--balanceCard-height) -
  //           var(--table-height) - 100px
  //       ) * 0 + 1
  //   )
  // );
  @media (max-height: 768px) {
    --chart-height: 22vh;
    --table-height: calc(
      100vh - var(--header-height) - var(--balanceCard-height) -
        var(--chart-height) - 221px
    );
  }
}
.selectName {
  font-size: 9px;
}

.typeTag {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff5d3;
  border-radius: 8px;
  color: #c9a11a;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-left: 6px;
}

.payeeSelection {
  margin-top: 6px;
  display: flex;
  font-size: 12px;
  > div:first-child {
    > div:first-child {
      display: inline-flex;
      align-items: center;
      > span {
        margin-right: 5px;
      }
    }
  }
}
.payeeSelectionDetails {
  font-size: 10px;
  color: #9ba3ab;
  line-height: 12.1px;
}

.refreshBtn {
  background-color: #f3f3ff !important;
  color: #7372ff !important;

  span {
    margin-left: 5px !important;
  }

  &:active {
    border: none !important;
  }
  &:focus {
    border: none !important;
  }
}

.addBtn {
  display: flex;
  margin-right: 10px;
  gap: 10px;
}

.addFundBtn {
  font-size: 14px !important;
  width: 142px !important;
  height: 44px !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
  &:disabled {
    .arrowBtnWithBg {
      color: #9ba3ab;
    }
  }
}

.arrowBtnWithBg {
  color: #7372ff;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  justify-content: space-between;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.balanceCard {
  height: var(--balanceCard-height);
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
    &::before {
      display: none;
    }
  }
  color: #3b3f43;
  .cardRight {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
}

.totalBalance {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > div {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
  .amount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 36px;
      margin-left: 24px;
    }
  }
}

.layoutRight {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
}

.fundTransferCard {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 0 0 16px 0;
  width: 378px;
}

.fundTransferForm {
  input {
    width: 330px;
  }
  padding: 0 24px;
  min-height: var(--fundTransferForm-height);
  // overflow-x: auto;
  @media (max-height: 830px) {
    .transferType {
      display: inline-block;
      width: calc(50% - 8px);
      float: left;
    }
    .transferAmount {
      display: inline-block;
      width: calc(50% - 4px);
      float: right;
    }
    .transferReason {
      display: inline-block;
      width: 100%;
    }
  }
}

.fundTransferCardHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px 8px 24px;
}

.fundTransferCardFooter {
  padding: 6px 24px 24px 0;
}

.accountDetailsCard {
  > div {
    padding: 0;
    margin: 0;
  }
  width: 378px;
}

.accountDetailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 8px 24px;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #3b3f43;
  }
  svg {
    cursor: pointer;
  }
}

.accountDetails {
  padding: 8px 16px;
  margin: 0 24px;
  background-color: #fbfbfb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.bankLogo {
  border-radius: 50%;
  background-color: #f0f2f9;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 39px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.accNo {
  display: flex;
  flex-direction: column;

  > span:first-child {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #9ba3ab;
  }

  > span:last-child {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
  }
}

.shareBtn {
  margin: 16px 24px 24px 24px;
}

.tableWrapper {
  width: 53vw;
}

.tableCard {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 8px 0;
}

.tableCardFilters {
  display: flex;
  margin-left: 35px;
}

.table {
  height: var(--table-height);
  overflow: hidden;
  padding: 0 16px;
  &::before {
    display: table;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #fff, transparent);
    z-index: 9 !important;
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 5px 24px;
}
.tableViewAllBtn {
  margin-left: auto;
  padding: 0;
}
.cashflowCardHeader {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // padding: 16px 24px;
  width: 100%;
  margin-bottom: 8px;
}

.cashflowCardFilters {
  margin: 0;
}

.cashflow {
  padding: 16px 0px 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 70%;
  height: 100%;
}

.cashflowCardRight {
  border-left: 1px solid #f0f0f0;
  padding: 0;
  margin: 0;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  > div:first-child {
    border-bottom: 1px solid #f0f0f0;
  }
}

.cashflowChart {
  width: 100%;
  height: var(--chart-height);
}

.cashflowCard {
  margin: 16px 0 8px 0;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  color: #3b3f43;
}

.cashflowLegend {
  & > div {
    padding-top: 0;
    padding-bottom: 0;
    > span {
      display: inline-block !important;
      margin-left: 10px !important;
    }
  }
}
.cardRight {
  // border-left: 1px solid #f0f0f0;
  padding: 16px;
  margin: 0;
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // > div:first-child {
  //   border-bottom: 1px solid #f0f0f0;
  // }
}

.totalWithdrawls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 33px;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  border-radius: 8px;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ee6969;
    width: 40%;
  }
  .icon {
    background-color: #f3f3ff;
    color: #ee6969;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 11px;
  }
}

.totalDeposits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 33px;
  margin: 16px 0 0 0;
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  border-radius: 8px;
  h3 {
    color: #1dbc83;
    width: 40%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #e1fff4;
    color: #1dbc83;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 11px;
  }
}

.amount {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    margin: 0 4px;
    display: flex;
    align-self: flex-start;
  }
}

.modalInfoText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #3b3f43;
  margin-bottom: 24px;
}

.modalDetailsContainer {
  background: #f6f7fa;
  border-radius: 8px;
  width: 386px;
  margin-bottom: 16px;
  padding: 16px;
  > h4 {
    margin-bottom: 22px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
  }
  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    text-align: left;
    > span:first-child {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #9ba3ab;
      width: 50%;
    }
    > span:last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #3b3f43;
      width: 50%;
    }
  }
}

.modalFooter {
  margin: 36px 0 0 0;
  border-top: 1px solid #d1d5dd;
  > span {
    background: #fff;
    position: absolute;
    left: 40.5%;
    bottom: 102px;
    padding: 0 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #9ba3ab;
  }
  > div {
    margin-top: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      > img {
        width: 30px;
        margin-bottom: 12px;
      }
      > span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        color: #3b3f43;
      }
    }
  }
}

.barSeriesRoundedCorner {
  rect {
    rx: 4px;
    ry: 4px;
  }
}

.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}

.addFundsInput > input {
  text-align: end;
}
