.setupOtterzComponentContainer {
  display: flex;
  justify-content: center;
  // align-items: center;
  // padding: 0 0 15vh 0;
  padding: 15vh 0 15vh 0;
  height: 100%;
  width: 100vw;

  .setupOtterzComponent {
    max-width: 500px;
    width: 100%;
    text-align: left;

    .connectRow {
      margin: 20px 0;
      gap: 5rem;
      .connectedButton {
        background: black;
        transition: background 0.3s;
        border-color: black;
        color: white;
        padding: 1rem 3rem;
        border-radius: 8px;
        
        &:hover {
          background: rgba(255, 255, 255, 0.5);
          border-color: rgba(255, 255, 255, 0.5);
          color: black;
        }
      }
    }

    .subText {
      color: #82898F80;
      margin: 20px 0;
    }

    .accountRow {
      margin-bottom: 15px;
      text-align: left;
      align-items: center;
      .selectBusiness {
        margin: 10px 0;
        width: 100%;
      }
    }

    .continueButton {
      width: 100%;
    }
  }
}
