.landingPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0;
}

.header {
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBar {
  width: 50%;
}

.table {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}