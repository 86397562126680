// .wrapper {
//   margin: 0;
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   height: 100%;
// }

// .sidebarWrapper {
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   width: 17vw;
// }

// .sectionWrapper {
//   width: 83vw;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   right: 0;
//   background-color: #f6f7fa;
// }

.wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.layout {
  flex: 1;
}

.sidebar {
  height: 100%;
  background-color: #001529;
  border-right: 1px solid #f0f0f0;

  .logo {
    // height: 32px;
    // margin: 16px;
    padding: 2rem;
    // background: linear-gradient(180deg, #9365dd 0%, #001529 100%);
    background: #000000;
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #f0f0f0;
  }
}

.sectionWrapper {
  flex: 1;
//   padding: 24px;
  background-color: #f0f0f0;
  overflow-y: auto;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #c8510300;
}
