.wrapper {
  margin: 0;
  padding: 20px 28px 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --bottomRow-height: 138px;
  --todayStatsBody-height: 110px;
  --todayStatsBody-max-height: 204px;
  --merchantCard-height: 68px;
  --table-height: calc(
    100vh - var(--header-height) - var(--merchantCard-height) -
      var(--bottomRow-height) - 245px
  );
  --chart-height: calc(
    100vh - var(--header-height) - var(--todayStatsBody-max-height) -
      var(--bottomRow-height) - 130px
  );
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.bottomRow {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.body {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  > div:first-child {
    width: 49.3%;
  }
  > div:last-child {
    width: 49.3%;
    display: flex;
    flex-direction: column;
  }
}

.todayStats {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f0f0f0;
}

.todayStatsBody {
  padding: 20px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: var(--todayStatsBody-height);
  max-width: var(--balanceCard-max-height);
}

.netSales {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 15px 5px 15px;
  margin: 0;
  text-align: right;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1dbc83;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #3b3f43;
    }
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 19px;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      > span:last-child {
        margin-left: 2px;
      }
    }
  }
}
.transactions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  // width: 33.3%;
  .icon {
    background-color: #f3f3ff;
    color: #7372ff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    h3 {
      color: #7372ff;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      width: 70%;
    }
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #3b3f43;
    }
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 19px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > span:last-child {
        margin-left: 2px;
      }
    }
  }
}

.avgTicketSize {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  // width: 33.3%;
  .icon {
    background-color: #e1fff4;
    color: #1dbc83;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    h3 {
      color: #1dbc83;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      width: 70%;
    }
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #3b3f43;
    }
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 19px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > span:last-child {
        margin-left: 2px;
      }
    }
  }
}
.refunds {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: #1dbc83;
  margin: 0;
  // width: 33.3%;
  .icon {
    background-color: #f3f3ff;
    color: #7372ff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    h3 {
      color: #7372ff;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      width: 70%;
    }
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #3b3f43;
    }
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 19px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > span:last-child {
        margin-left: 2px;
      }
    }
  }
}
.separator {
  background-color: #f0f0f0;
  width: 1px;
  height: 100%;
}
.recentTransactions {
  > div {
    padding: 12px;
    margin: 0;
  }
  margin: 16px 0;
  width: 100%;
  height: auto;
}

.optionCardLight {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  width: 15.5%;
  height: var(--bottomRow-height);
  // margin: 0 0 0 16px;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    z-index: 99;
    transition: transform 0.7s ease-in-out;
  }

  .optionCardComingSoonLabel {
    color: #d3b25a;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 150%;
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .optionIconBackdropLight {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left: 35px;
    top: 35px;
    background: #f6f7fa;
  }
  .arrowBtnWithBg {
    color: #fff;
    background-color: #7372ff;
    border-radius: 50%;
    cursor: pointer;
  }
  .arrowDropBtnWithBg {
    color: #000;

    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
  }

  .cardAction {
    color: #3b3f43;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    span {
      width: 82px;
    }
  }
  .cardActionDisabled {
    color: #9ba3ab;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    span {
      width: 82px;
    }
  }
}

.optionCardLight:hover {
  .arrowDropBtnWithBg {
    transform: rotate(180deg);
    color: #7372ff;
  }
}

.merchantAccountDetails {
  height: var(--merchantCard-height);
  background-color: #fbfbfb;
  margin: 0 24px 24px 24px;
  padding: 8px;
  border-radius: 8px;
}

.merchantAccountDetailsSpin {
  height: var(--merchantCard-height);
  display: flex;
  align-items: center;
  justify-content: center;
}
.merchantDetails {
  height: var(--merchantCard-height);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  > div {
    margin-right: 30px;
    justify-content: space-between;
    > span {
      color: #9ba3ab;
      font-size: 12px;
    }
  }
}

.tableCardBody {
  padding: 0 20px 20px 20px;
  width: 98%;
  height: var(--chart-height);
}

.tableCard {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 0 0 16px 0;
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 5px 24px;
}

.tableCardFilters {
  display: flex;
  padding: 5px 24px 16px 24px;
}

.salesBottomCardFilters {
  display: flex;
  padding: 5px 24px 5px 0px;
}

.table {
  height: var(--table-height);
  // overflow:hidden;
  overflow: hidden;
  &::before {
    display: table;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #fff, transparent);
    z-index: 9 !important;
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.modal {
  > div > div {
    overflow-y: auto;
  }
}

.modalHeader {
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3b3f43;
    margin-bottom: 16px;
  }
}

.menuItem {
  height: 48px;
  width: 246px;
  margin-bottom: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #7372ff;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(108, 99, 255, 0.3);
  &:hover {
    box-shadow: 0px 4px 10px 3px rgba(108, 99, 255, 0.4);
  }
  > span {
    font-size: 12px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  .arrowBtnWithBg {
    color: #fff;
    background-color: #7372ff;
    border-radius: 50%;
    cursor: pointer;
  }
  .menuItemTitle {
    display: inline-flex;
    align-items: center;
    > span {
      margin-left: 16px;
    }
  }
}

.menuWrapper {
  background-color: transparent;
  box-shadow: none;
}

.paymentMedium img {
  border: 1px solid #f0f0f0;
  margin: 2px;
  border-radius: 2px;
  padding: 0;
}

.modalFooter {
  margin: 36px 0 0 0;
  border-top: 1px solid #d1d5dd;
  > span {
    background: #fff;
    position: absolute;
    left: 40.5%;
    bottom: 102px;
    padding: 0 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #9ba3ab;
  }
  > div {
    margin-top: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      > img {
        width: 30px;
        margin-bottom: 12px;
      }
      > span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        color: #3b3f43;
      }
    }
  }
}
.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  div {
    display: none;
  }

  > img {
    width: 380px;
    height: 380px;
  }
}

.qrCodePrint {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  div {
    text-align: center;
    display: block;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  > img {
    width: 380px;
    height: 380px;
  }
}

.spinner {
  margin-top: 300px;
  justify-content: center;
}

.paymentMedium {
  display: flex;
  gap: 5px;
  align-items: center;

  > div {
    height: 38px;

    img {
      width: 41px;
      height: 100%;
    }
  }
}

div.cardImage {
  height: auto;

  img {
    width: 41px;
    height: auto;
  }
}
