.wrapper {
  background-color: #e5e5e5;
  margin: 0;
  // height: 100vh;
}

.purpleBackground {
  background-color: #7372ff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 65vh;
}
