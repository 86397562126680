.wrapper {
  margin: 0;
  padding: 20px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --reportCard-height: calc(100vh - var(--header-height) - 35px);
  --quickReportCard-height: 126px;
  --quickReportCard-max-height: 156px;
  --table-height: calc(
    100vh - var(--header-height) - var(--quickReportCard-max-height) - 277px
  );
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  justify-content: space-between;
}

.body {
  width: 67%;
  display: flex;
  flex-direction: column;
}

.layoutRight {
  width: 33%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.subHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  > span:first-child {
    color: #3b3f43;
    margin-right: 8px;
  }
  > span:last-child {
    color: #82898f;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3b3f43;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.createReportCard {
  > div {
    display: flex;
    flex-direction: column;
    height: var(--reportCard-height);
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
}

.linkButton {
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
}

.reportOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 16px auto 0 auto;
}

.addFilters {
  border-top: 1px solid #e5e7eb;
}

.quickReportsWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 0;
}

.quickReportCard {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: var(--quickReportCard-height);
    max-height: var(--quickReportCard-max-height);
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  .quickReportTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    > span:first-child {
      font-weight: 700;
    }
    > span:last-child {
      font-weight: 400;
    }
  }
  .icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
  }
  flex-basis: 100%;
  flex: 1 20%;
  margin: 16px 16px 0 0;
  height: var(--supportTopicCard);
  background: #7372ff;
  color: #fff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
}

.tableWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 0 0;
}

.tableCardLeft {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 8px 8px 0 0;
  width: 100%;
}

.tableCardRight {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 8px 0 0 8px;
  width: 100%;
}

.table {
  height: var(--table-height);
  overflow: auto;
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 5px 24px;
}

.actionIcon {
  cursor: pointer;
}

.inputPrefix {
  margin-top: 8px;
  > div {
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
    &:hover {
      box-shadow: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}

.filterInput {
  margin: 16px 0;
}

.confirmModal {
  text-align: center;
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}
