.modal {
  > div > div {
    overflow-y: auto;
  }
}

.card {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 0 0 16px 0;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 5px 24px;
}

.billingAccountDetails {
  background-color: #fbfbfb;
  margin: 0;
  padding: 16px;
  border-radius: 8px;
  > h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
    margin-bottom: 22px;
  }
  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    > span:first-child {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #82898f;
    }
    > span:last-child {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #1dbc83;

      &.result {
        color: #ee6969;
      }
    }
  }
}

.billingDetails {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  > div {
    width: 49%;
    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      width: 125px;
      > span:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #82898f;
        margin-bottom: 4px;
      }
      > span:last-child {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #3b3f43;
      }
    }
  }
}

.footer {
  display: inline-flex;
  justify-content: space-around;
  margin-right: 90px;
  button {
    display: flex;
    align-items: center;
  }
}
.bottomBar {
  display: flex;
  justify-content: space-around;
  align-self: center;
  margin-top: 12px;
  width: 100%;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.additionalInfo {
  width: 250px !important;
}

.cardDetail {
  display: flex;
  gap: 5px;
  align-items: center;

  > div {
    height: 38px;

    img {
      width: 41px;
      height: 100%;
    }
  }
}
