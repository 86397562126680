.wrapper {
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.greetingsSection {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  margin: 0.5rem;
  padding: 25px;
  font-size: 1.1rem;
}

.chatHeading {
  color: #82898F;
  text-align: left;
  font-size: small;
  background: white;
  padding: 20px 0 10px 20px;
  margin: 0 0.5rem;
}

.sidebarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 17vw;
  background-color: #f6f7fa;
}

.sectionWrapper {
  width: 83vw;
  background-color: #f6f7fa;
  height: 100%;
}


.welcomeScreen{
  height: 100%;
  width: 100%;
  display: flex;
}

.welcomeScreenLeft {
  height: 100%;
  width: 50%;
  background-color: #7372FF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  div{
    margin-top: 50px;
    margin-left: 50px;
    width: 100%;
    h3 {
      color: #fff;
      text-align: left
    }
  }
}

.welcomeScreenRight{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    margin-top: 50px;
    margin-bottom: 26px
  }
  p {
    color: #82898F
  }
}


// Customize styles for Chatbot component
.container {
  padding: 20px;
}

.message-list {
  margin-bottom: 20px;
}

.message-input {
  margin-bottom: 10px;
}

.file-upload {
  margin-bottom: 10px;
}

/* New Styles */

.messagesContainer {
  flex-grow: 1;
  overflow-y: auto;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  margin: 35px 0.5rem 0;
  padding: 25px;
  font-size: 1.1rem;
}

.message {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
  flex-direction: column;
  margin: 3rem 0;
  gap: 0.5rem;
}

.receiver {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem 0;
  gap: 1rem;
}

:global(.ant-avatar-image) {
  background: transparent;
  width: 45px;
  height: auto;
  // margin: 0 10px 0 0;
}

.messageAvatar {
  margin: 10px 0 0 0;
}

.username {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.aiUsername {
  display: flex;
  align-items: center;
}

.messageContent {
  display: flex;
  width: 80%;
  gap: 1rem;
}

.messageContentSender {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  gap: 1rem;
}

.messageAuthor {
  // font-weight: bold;
  font-size: 0.9rem;
  color: #979797;
}

.messageBody {
  margin-top: 5px;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.031372549);
  padding: 1rem 0 1rem 1rem;
  background: #F7F7F7;
  border-radius: 8px;
  flex: 1;
}

.typing {
  margin: 0.5rem;
  font-style: italic;
  color: #979797;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  position: relative;
}

.inputContainer textarea {
  flex-grow: 1;
  margin-right: 10px;
}

.inputContainer .submitBtn {
  /* padding: 0rem 2rem 0 2rem; */
  margin: 0;
  position: absolute;
  right: 30px;
  bottom: 11px;
  background: transparent;
  color: black;
  border: none;
}

.inputContainer .fileInput {
  margin-right: 10px;
  position: absolute;
  right: 80px;
  bottom: 11px;
  fill: #fcfcfc;
}
