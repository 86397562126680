.tabHeader {
  //   width: 234px;
  height: var(--tabHeader-height);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.tabHeading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.headingIncoming {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1dbc83;
}

.headingOutgoing {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #7372ff;
}

.iconContainerIncoming {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  background-color: #e1fff4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.iconContainerOutgoing {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  background-color: #f3f3ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.count {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.2;
  color: #3b3f43;
}

.tabFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.trend {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #82898f;
}

.spinner {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}
