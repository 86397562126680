.termsModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.termsText {
  margin-bottom: 30px;
  max-width: 600px;

  p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
  }
}

.termsInputSection {
  h3 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
  }

  .inputField {
    width: 300px;
    margin-bottom: 20px;
  }

  .agreeButton {
    width: 150px;
  }
}
