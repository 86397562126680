.modal {
  display: flex;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}
