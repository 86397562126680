.title {
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #3b3f43;
  }
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #3b3f43;
  }
}

.transactionCard {
  background-color: #f3f3ff;
  border-radius: 8px;
  width: 273px;
  margin: 20px 0 0 0;
  > div {
    padding: 16px;
  }
  &:after {
    content: "";
    position: relative;
    display: block;
    height: 10px;
    bottom: -5px;
    left: 0;
    right: 0;
    background: linear-gradient(
        45deg,
        transparent 33.333%,
        #f3f3ff 33.333%,
        #f3f3ff 66.667%,
        transparent 66.667%
      ),
      linear-gradient(
        -45deg,
        transparent 33.333%,
        #f3f3ff 33.333%,
        #f3f3ff 66.667%,
        transparent 66.667%
      );
    background-size: 8px 20px;
    background-position: 0 -10px;
  }
}

.payee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  > span {
    background-color: #eddfff;
    color: #a97be3;
    padding: 0px 4px;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

.payeeDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  > span:first-child {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #9ba3ab;
    margin-bottom: 4px;
  }
}

.transaction {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  margin: 8px 0 0 0;
  padding: 8px 0 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #3b3f43;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-weight: bold;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 3px double rgba(0, 0, 0, 0.05);
  padding: 8px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #3b3f43;
}

.summaryText {
  margin-top: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #7372ff;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
