.wrapper {
  margin: 0;
  padding: 20px 28px 10px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 60px;
  --table-height: calc(
    100vh - var(--header-height) - var(--topRow-height) - 180px
  );
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.topRow {
  height: var(--topRow-height);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.body {
  display: flex;
  flex-direction: column;
  > span {
    margin: 26px 0;
  }
}

.optionCard {
  cursor: pointer;
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 269px;
  height: 54px;
  margin: 0 0 0 16px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    width: 40px;
    height: 40px;
  }
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 0 12px;
  }
}

.table {
  height: var(--table-height);
  width: 100%;
  overflow: auto;

  :global {
    .ant-table {
      height: calc(var(--table-height) - 200px) !important;
    }
  }
}

.modal {
  display: flex;
}

.modalLeft {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
}

.modalRight {
  border-left: 1px solid #f0f0f0;
  padding: 24px;
  overflow: hidden;
}

.payee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  > span {
    background-color: #eddfff;
    color: #a97be3;
    padding: 0px 4px;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

.payeeDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #9ba3ab;
  }
}

.transaction {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  margin: 4px 0;
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-weight: bold;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  padding: 8px 0;
}

.summaryText {
  margin-top: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #7372ff;
}

.typeTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  background: #fff5d3;
  border-radius: 2px;
  color: #c9a11a;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.actionIcon {
  cursor: pointer;
}

.searchPayment {
  width: 378px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  > span {
    margin-right: 15px;
  }
}

.payeeFilters {
  display: flex;
  flex-direction: row;
}

.filtersCard {
  margin-left: 16px;
  padding: 10px 14px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    padding: 0;
    margin: 0;
  }
}

.filtersGroup {
  button {
    margin-right: 5px;
  }
}

.refreshBtn {
  background-color: #f3f3ff !important;
  color: #7372ff !important;

  span {
    margin-left: 5px !important;
  }

  &:active {
    border: none !important;
  }
  &:focus {
    border: none !important;
  }
}

.tableRow {
  cursor: pointer;
}

.balanceCard {
  margin: 20px 0;
  height: 150px;

  span {
    font-weight: 500;
  }
}

.totalBalance {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  width: 100%;

  h4 {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .amount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;

    sup {
      display: inline-block;
      font-weight: 400;
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
