.wrapper {
  margin: 0;
  padding: 20px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --statementsCardBody-height: calc(100vh - var(--header-height) - 135px);
  --tableBody-height: calc(100vh - var(--header-height) - 155px);
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.headerName {
  display: flex;
  align-items: center;
  h2 {
    margin-left: 15px;
  }
}

.buttonWrapper {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  justify-content: space-between;
}

.body {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.bodyRight {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.table {
  height: var(--tableBody-height);
  width: 98%;
  overflow-y: auto;
}

.modal {
  display: flex;
}

.modalLeft {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
}

.modalRight {
  border-left: 1px solid #f0f0f0;
  padding: 24px;
  overflow: hidden;
}

.typeTag {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  background: #fff5d3;
  border-radius: 2px;
  color: #c9a11a;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.searchStatement {
  width: 378px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  > span {
    margin-right: 15px;
  }
}

.statementFilters {
  display: flex;
  flex-direction: row;
}

.filtersCard {
  margin: 0 16px;
  padding: 10px 14px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  > div {
    padding: 0;
    margin: 0;
  }
}

.filtersGroup {
  button {
    margin-right: 0;
  }
}

.statementCard {
  > div {
    padding: 0;
    margin: 0;
  }
}

.statementCardHeader {
  display: flex;
  flex-direction: column;
  padding: 16px 24px 5px 24px;
  border-bottom: 1px solid #d1d5dd;
  .headerTopRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filterRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #9ba3ab;
    }
  }
}

.statementCardBody {
  height: var(--statementsCardBody-height);
  overflow: auto;
  padding: 28px 24px;
}

.actionRow {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
}

.actionCard {
  > div {
    padding: 0;
    margin: 0;
  }
  height: 37px;
  margin-right: 16px;
  button {
    display: flex;
    align-items: center;
    top: 3px;
  }
}

.filterButtons {
  display: flex;
  flex-direction: row;
}

.dropdownFilter {
  display: flex;
  align-items: center;
  color: #3b3f43;
  &:hover {
    color: #000;
  }
  &:focus {
    color: #000;
  }
  &:active {
    color: #000;
  }
}

.statementListCard {
  &:hover {
    cursor: pointer;
  }

  background: #7372ff;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  height: 79px;
  margin-bottom: 24px;
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  > div:first-child {
    h3 {
      color: #fff;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }
    span {
      color: #d1d5dd;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .cardAction {
    > span {
      color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      margin-right: 8px;
    }
    display: flex;
    align-items: center;
  }
}

.optionTxtButton {
  border: 1px solid #d1d5dd;
  height: 36px;
  padding: 0 20px;
  &:hover {
    color: #7372ff;
  }
  &:active {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
  &:focus {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
}

.customFieldOptionTxtButton {
  border: 1px solid #d1d5dd !important;
  height: 36px;
  padding: 0 20px;
  background: transparent !important;

  &:hover {
    color: #7372ff;
  }
}

.optionTxtButtonSelected {
  margin-right: 5px;
  color: #7372ff;
  background-color: #f3f3ff;
  &:hover {
    color: #7372ff;
  }
  // &:active {
  //   color: #7372ff;
  //   background: #f3f3ff;
  //   border-radius: 4px;
  // }
  &:focus {
    color: #7372ff;
    background: #f3f3ff;
    // border-radius: 4px;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}
.spinnerContainer {
  > div > div {
    display: block;
    margin: auto;
    margin-top: 200px;
  }
}
.closeIcon {
  color: crimson;
  padding-top: 5px;
  cursor: pointer;
}

.tabRadioButton {
  height: 36px;
  > span {
    color: #3b3f43;
  }
  > span:first-child > input[type="radio"]:checked {
    color: #fff;
    background-color: #7372ff;
  }
  input[type="radio"]:checked {
    color: #fff;
    background-color: #7372ff;
  }
}

.tabRadioButtonFocused {
  height: 36px;
  background-color: rgba(115, 114, 255, 0.2) !important;
  > span {
    color: #7372ff;
  }
}

.transactionStatusFilters {
  margin: 1px 16px 0 16px;
}
