.wrapper {
  margin: 0;
  padding: 20px 0 10px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 60px;
  --cards-section-height: calc(
    100vh - var(--header-height) - var(--topRow-height) - 60px
  );
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(246, 247, 250, 1);
  z-index: 99;
  text-align: left;
  padding: 28px;
  display: flex;
  justify-content: space-between;
  width: 83%;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    color: #3b3f43;
    margin: 8px 0px;
  }
}

.headerName {
  display: flex;
  align-items: center;
  h2 {
    margin-left: 15px;
  }
}

.buttonWrapper {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subHeaderWrapper {
  width: 100%;
  padding: 80px 0 48px 0;
  display: flex;
  justify-content: space-between;
  height: var(--topRow-height);
}

.bodyWrapper {
  padding: 180px 0 0 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--cards-section-height);
}

.optionCard {
  cursor: pointer;
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 301px;
  height: 54px;
  margin: 0 0 0 16px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    width: 40px;
    height: 40px;
  }
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 0 12px;
  }
}

.searchUser {
  width: 378px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  > span {
    margin-right: 15px;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
  scroll-margin: inherit;
  tbody > tr:hover > td {
    cursor: pointer;
  }
}

.actionIcon {
  cursor: pointer;
}

.userCard {
  flex: 0 0 48.4%;
  align-self: flex-start;
  align-content: space-between;
  margin: 0 8px 8px 0;
  > div {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--secondRow-height);
    margin: 0;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  > div > div:first-child {
    width: auto;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3b3f43;
    > div {
      margin: auto;
      > span:nth-child(1) {
        font-size: 12px;
      }
      > span:nth-child(2) {
        // font-size: 20px;
        margin: 0 20px 0 20px;
        color: #82898f;
      }
      > span:nth-child(3) {
        font-size: 12px;
      }
    }
  }
}
.actionIcon {
  cursor: pointer;
}
.column {
  float: left;
  width: 50%;
}
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
.RoleSelection {
  margin-top: 6px;
  display: flex;
  font-size: 12px;
  > div:first-child {
    > div:first-child {
      display: inline-flex;
      align-items: center;
      > span {
        margin-right: 5px;
      }
    }
  }
}
.roleSelectionDetails {
  font-size: 10px;
  color: #9ba3ab;
  line-height: 12.1px;
}

.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}

.inactiveLabel {
  padding-left: 2px;
  font-size: x-small;
  opacity: 50%;
}
