.wrapper {
  margin: 0;
  padding: 20px 28px 10px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 60px;
  --table-height: calc(
    100vh - var(--header-height) - var(--topRow-height) - 195px
  );
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.topRow {
  height: var(--topRow-height);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.body {
  display: flex;
  flex-direction: column;
  > span {
    margin: 16px 0;
  }
}

.optionCard {
  cursor: pointer;
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 269px;
  height: 54px;
  margin: 0 0 0 16px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    width: 40px;
    height: 40px;
  }
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 0 12px;
  }
}

.table {
  height: 100%;
  width: 100%;
  // overflow: auto;
  tbody > tr:hover > td:nth-child(2) {
    cursor: pointer;
  }
}

.actionIcon {
  cursor: pointer;
}

.search {
  width: 378px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  border-radius: 0px;
  > span {
    margin-right: 15px;
  }
}

.filterSelect {
  width: 134px;
  border: none;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  
  .ant-select-selector {
    border: 1px solid #d9d9d947 !important;
  }

  :global {
    .ant-select-selector {
      padding-right: 25px !important;
      // border: 1px solid #d9d9d9 !important;
    }
  }
}

.filterSelect div {
  border: 1px solid #d9d9d947 !important;
}

.tableCardFilters label {
  display: flex;
  margin: 0 !important;
}
.tableCardFilters label::before {
  display: none;
  opacity: 0;
}

.invoiceFilters {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3b3f43;
  }
}

.filtersCard {
  margin-left: 16px;
  padding: 10px 14px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    padding: 0;
    margin: 0;
  }
}

.filtersGroup {
  button {
    margin-right: 5px;
  }
}

.optionTxtButton {
  margin-right: 5px;
  padding: 0.5px;
  // border: 1px solid #d1d5dd;
  &:hover {
    color: #7372ff;
  }
  &:active {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
  &:focus {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
}

.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}

.statusSelect {
  font-size: 12px;
  height: 16px !important;
  > div::after {
    line-height: 12px !important;
  }
  > div > span:last-child {
    line-height: 12px !important;
  }
  > span {
    display: flex;
    align-items: center;
  }
  :global {
    .ant-select-selector {
      height: auto !important;
      padding: 0 !important;
    }
  }
}
