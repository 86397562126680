.wrapper {
  margin: 0;
  padding: 20px 28px 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.layout {
  width: 100%;
  padding-top: 80px;
}
.bodyWrapper {
  display: flex;
}
.body {
  width: 100%;
}

.infoWrapper {
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  height: calc(100vh - var(--header-height) - 35px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoFooter {
  border-top: 1px solid #d1d5dd;
  padding: 16px 67px;
  margin: 16px 67px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3b3f43;
    cursor: pointer;
  }
}

.infoCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 0 0;
  overflow-y: auto;
}

.infoCard {
  width: 52%;
  height: 72px;
  background: #f6f7fa;
  border-radius: 8px;
  padding: 24px;
  margin: 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > div {
      margin-left: 13px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #3b3f43;
    }
  }
  > div:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}

.close {
  // width: 52%;
  height: 72px;
  padding: 24px;
  margin: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ee6969;
  cursor: pointer;
  position: relative;
  left: -280px;
}

.comingSoon {
  color: #d3b25a;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 150%;
  text-transform: uppercase;
}

.changePlan {
  display: flex;
  flex-direction: column;
  align-items: flex-end !important;
  > div:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7372ff;
    // cursor: pointer;
  }
}

.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}

.closeRequested {
  width: 52%;
  height: 72px;
  padding: 24px;
  margin: 8px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #82898f;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > div:last-child {
      > div:first-child {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #82898f;
      }
      > div:last-child {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #82898f;
      }
    }
  }
  > div:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7372ff;
    cursor: pointer;
    margin: 18px 0 0 28px;
  }
}

.openedOn {
  font-size: 12px;
  font-weight: normal;
  opacity: 0.7;
  margin-top: -9px;
}

.accountStatus {
  flex-direction: column;
  align-items: flex-end !important;
}
