.categorization {
    padding: 20px;
    background: #ffffff;
    width: calc(100%);

    .confirmButton {
      margin-top: 20px;
      float: right;
    }

    .customDropdownContainer {
      width: 100%;
      max-width: 100%;
      margin: 20px auto;
      position: relative;
      text-align: left;
    }

    .selectedValue {
      width: fit-content;
      padding: 15px 10px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: white;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
    }
    .selectedValue:hover {
      border: 1px solid #afacfe;
      box-shadow: inset 0 0 1px #afacfe;
    }

    .optionsContainer {
      width: 20vw;
      position: absolute;
      top: 100%;
      left: 0;
      max-width: 400px;
      max-height: 200px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: white;
      overflow-y: auto;
      z-index: 1000;
    }

    .searchContainer {
      position: sticky; /* Make it sticky */
      top: 0;           /* Stick to the top */
      background-color: white; /* Ensure it doesn't blend with the options */
      padding: 10px;
      z-index: 1;       /* Make sure it's above the options */
      border-bottom: 1px solid #ddd; /* Optional: Add a subtle separator */
    }

    .option {
        padding: 10px;
        cursor: pointer;
    }

    .option:hover {
      background-color: #dcdbff;
      color: #515151;
    }

    .level0 {
        padding-left: 1rem;
    }

    .level1 {
        padding-left: 3rem;
    }

    .level2 {
        padding-left: 5rem;
    }

    .level3 {
        padding-left: 7rem;
    }

    /* Add more levels as needed */


    .bankCardRow {
      display: flex;
      gap: 2vw;
      width: 80vw;
      overflow-x: auto;
    
      // Custom scrollbar styling
      &::-webkit-scrollbar {
        height: 10px; // Make the scrollbar thinner
      }
    
      &::-webkit-scrollbar-track {
        background: #f0f0f0; // Light background for the scrollbar track
        border-radius: 10px;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #b0b0b0; // Gray color for the scrollbar thumb
        border-radius: 10px; // Rounded corners for a slick look
        border: 2px solid #f0f0f0; // Adds padding effect and makes the thumb look thinner
      }
    
      &::-webkit-scrollbar-thumb:hover {
        background-color: #909090; // Darker color when hovered for better visibility
      }
    }
    

    .bankCard {
      width: fit-content;
      min-width: 175px;
      height: fit-content;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
      background-color: white;
      border-color: transparent;
    }
    .bankCard:hover {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75);
    }
}
  
  