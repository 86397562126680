.wrapper {
  margin: 0;
  padding: 20px 28px 10px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 55px;
  --table-height: calc(
    100vh - var(--header-height) - var(--topRow-height) - 180px
  );
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  justify-content: space-between;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.search {
  width: 378px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  > span {
    margin-right: 15px;
  }
}

.searchBar {
  height: var(--topRow-height);
  display: flex;
  flex-direction: row;
}

.actionRow {
  margin: 26px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
