.sessionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  width: 75%;
  height: 100vh;
  padding: 0 50px;

  .heading {
    color: #3f63e2;
    font-weight: 700;
    font-size: 40px;
  }

  .info,
  .link,
  button {
    font-weight: 500;
    font-size: 30px;
  }
}
