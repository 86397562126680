.setupOtterzComponentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 0 0 15vh 0;
    box-sizing: border-box;
  
    .setupOtterzComponent {
      max-width: 440px;
      width: 100%;
      text-align: left;
  
      .emailCard {
        color: #82898F;
        border: 1px solid #D1D5DD;
        margin-bottom: 2rem;
      }

      .subtitle {
        margin-bottom: 20px;
      }
  
      .ownerRow {
        width: 100%;
        margin-bottom: 10px;
  
        .ant-col {
          display: flex;
          align-items: center;
        }
  
        .removeIcon {
            color: blue;
            cursor: pointer;
            margin-left: 10px;
            position: relative;
            right: -94%;
            top: -60px;
        }
      }
  
      .addOwnerButton {
        margin-bottom: 20px;
        float: right;
      }
  
      .continueButton {
        width: 100%;
      }
    }
  }
  