.setupOtterzComponentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 15vh 0;
  height: 100vh;
  width: 100vw;

  .setupOtterzComponent {
    max-width: 420px;
    width: 100%;
    text-align: left;

    .connectRow {
      margin: 20px 0;
      gap: 5rem;
      .connectedButton {
        background-color: black;
        border-color: black;
      }
    }

    .subText {
      color: #82898F80;
      margin: 20px 0;
    }

    .accountRow {
      margin-bottom: 15px;
      text-align: left;
      align-items: center;
      .selectBusiness {
        margin: 10px 0;
        width: 100%;
      }
    }

    .flexRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem; /* Adjust as needed */
    }
    
    .connectButton {
      background-color: #008000;
      border-color: #008000;
      color: #fff;
      font-size: 0.9rem;
      width: 140px;
    }

    .continueButton {
      width: 100%;
    }

    .signupFormInputs {
        margin-top: 1rem;
    }
  }
}
