::-webkit-scrollbar {
  width:8px;
  height:8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffc7a2; 
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#c3c3c3; 
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff9752; 
}

.sidebar {
  margin: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
}

.selectedRouteClass {
  color: red;
  background: purple;
}

.footer {
  padding-left: 16px;
  padding-bottom: 35px;
  background: white;
  margin: 0 0 0 10px;
  
  :global {
    .ant-btn-round {
      height: auto !important;
    }

    .ant-btn {
      height: auto !important;
      border: none !important;
      padding: 2px !important;
    }
  }
}

.menu {
  color: #9ba3ab;
  border-right: none !important;
  // padding-left: 20px !important;
  flex: 1 1;
  margin: 0 10px !important;
  background-color: transparent;
}

.logo {
  width: -moz-fit-content;
  // width: 100%;
  height: 91px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  padding: 1.5rem;
  background: #000000;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: space-evenly;

  img {
    object-fit: contain;
    width: 80%;
  }

  // @media (max-width: 1200px) {
  //   width: 140px;
  // }
}

.profileMenu {
  width: calc(100% - 25px);
  font-style: normal !important;
  box-shadow: none !important;
  color: #9ba3ab !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  padding: 6px !important;
  border-radius: 10px;
  gap: 10px;

  :global {
    .ant-menu-submenu-title {
      height: auto !important;
    }
  }

  li {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.profileContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .name {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
    font-style: normal !important;
    color: #9ba3ab !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    width: 180px;
  }

  .icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  ul.ant-menu-sub.ant-menu-inline {
    background-color: #fff !important;
  }
}

.profilePicLogout {
  // color: #9ba3ab !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  color: black !important;
}

.dropdownBtn {
  color: #9ba3ab !important;
  box-shadow: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  padding: 6px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.supportBtn {
  color: #9ba3ab !important;
  box-shadow: none !important;
  background: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  padding: 6px 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: #3b3f43 !important;
  }
  &:active {
    color: #3b3f43 !important;
    border: none !important;
  }
  &:focus {
    color: #3b3f43 !important;
    border: none !important;
  }
}

.menuItem {
  height: 38px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #7372ff;

  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(108, 99, 255, 0.3);
  &:hover {
    box-shadow: 0px 4px 10px 3px rgba(108, 99, 255, 0.4);
  }
  > span {
    font-size: 15px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  .arrowBtnWithBg {
    color: #fff;
    background-color: #7372ff;
    border-radius: 50%;
    cursor: pointer;
  }
  .menuItemTitle {
    display: inline-flex;
    align-items: center;
    > span {
      margin-left: 16px;
    }
  }
}
.menuWrapper {
  background-color: transparent;
  box-shadow: none;
}

.profilePic {
  > img {
    object-fit: contain;
  }
}

.topicProIconBackdrop {
  display: inline-block;
  margin-left: 10px;

  span {
    color: #7372ff;
    font-weight: 600;
  }

  > div {
    display: flex;
    align-items: center;
    height: 20px;
    background-color: #ffe896;
    border-radius: 10px;
    padding: 12px 10px;
    gap: 4px;
  }

  > svg {
    color: #7372ff;
  }
}

.aibotbtn {
  border-radius: 4px !important;
  background-image: linear-gradient(98.16deg, #1B1A95 9.34%, #7435A6 63.16%) !important;
  background-size: 150% 100% !important;
  background-position: 100% 0 !important;
  color: white !important; 
  font-weight: 600 !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;

  transition: background-position 0.5s ease-in-out;
}
.aibotbtnDisabled {
  border-radius: 4px !important;
  background-image: linear-gradient(98.16deg, #747474 9.34%, #747474 63.16%) !important;
  background-size: 150% 100% !important;
  background-position: 100% 0 !important;
  color: white !important; 
  font-weight: 600 !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  cursor: not-allowed;
  // transition: background-position 0.5s ease-in-out;
}

.aibotbtn:hover {
  background-position: 0 0 !important;
}
