.input {
  height: 48px;
  width: 100%;
  border: 1px solid #d1d5dd;
  border-radius: 6px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Styles */
  .input {
    margin-top: -4px;
    height: 48px;
  }
}
