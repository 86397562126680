.greetingsSection {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    margin: 0.5rem;
    padding: 25px;
    font-size: 1.1rem;
}

.userManagement {
  margin: 0;

  h1 {
    margin: 4rem 0 1rem;
  }

  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }

  .actionIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    button {
      margin-left: 8px;
    }
  }
}

.inviteButton {
  background: linear-gradient(92.79deg, #7372FF 8.36%, #9772FF 92.75%);
  width: 183.45px;
  height: 44px;
  border-radius: 8px;
  float: right;
}

// label {
//   margin: 0 !important;
//   // width: 80px !important;
// }

h1 {
  margin: 4rem 0 3rem;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.rolesPermissions {
  // display: flex;
  // flex-direction: column;
  // height: 100vh;
  height: 100%;
  // padding: 0.1rem 0rem 0;

  .columnTitles {
    display: flex;
    justify-content: space-between;
  }

  .separator, .middleSeparator {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .columnsContainer {
    display: flex;
    width: 65%;

    .bankAccountsColumn, .quickBooksColumn {
      flex: 1;
    }

    .middleSeparator {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 0;
      height: 70vh;
    }

    .bankAccountRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .disconnectButton, .connectedButton {
      border: 1px solid black;
      color: black;
    }

    .reconnect {
      border: 1px solid black;
      color: white;
      background: #C85103;
    }

    .addBankAccount {
      text-align: right;
      margin: 0 0 1rem 0;
    }

    .quickBooksContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .connectedButton {
      background-color: green;
      border-color: green;
      color: white;
    }
  }

  .columnTitles {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 20vw;
  }

  .roleTitle, .permissionsTitle {
    font-weight: bold;
  }

  .separator {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }

  .content {
    display: flex;
    height: 100%;

    .rolesColumn, .permissionsColumn {
      flex: 1;
    }

    .verticalSeparator {
      width: 1px;
      background-color: #ccc;
      margin: 0 20px;
    }

    .rolesColumn {
      .ant-tabs-tabpane {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.content {
  :global(.ant-tabs-nav-list) {
    margin: 0 2rem 0 0 !important;
    width: 20vw !important;
  }
  
  :global(.ant-tabs-ink-bar) {
    width: 100% !important;
    z-index: -1 !important;
    border-radius: 2px !important;
    border: 0.5px solid #7372FF !important;
    background: rgba(246, 247, 250, 0.70) !important;
  }
}

.saveBadge {
  /* position: absolute; */
  /* top: 0px; */
  /* left: 0px; */
  background-color: #fff;
  color: #ffffff;
  padding: 5px 10px;
  // border-radius: 5px 0px;
  // font-size: 12px;
  background: black;
  border-radius: 8px 8px 0 0;
  text-align: center;
  width: 100%;
  font-size: 0.95rem;
  font-weight: 600;
  /* z-index: 1; */
}

.subscriptionCard{
  border: 1px solid #EEF1F1;
  position: relative;
  // height: 85vh;
  // height: 560px;
  // height: 100%;
  // width: 357px;
  width: 28%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  // box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.031372549);
  box-shadow: 0px 4px 4px 0px #0000000D;
  // gap: 3vh;
  justify-content: space-between;
  margin: 16px;
  align-items: center;
  border-radius: 8px;
  color: #82898F;
}

.cardContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  height: 62%;
  // width: 87%;
  width: 100%;
  // padding-bottom: 18px;
  // padding-top: 18px;
  padding: 15px 0;
  position: relative;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 5px;

  p {
      margin: 0;
  }
}

.taxTypeContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
}
