.contractors {
    padding: 20px;
    background: #ffffff;
    
    .confirmButton {
      margin-top: 20px;
      float: right;
    }

    .customDropdownContainer {
      width: 100%;
      max-width: 100%;
      margin: 20px auto;
      position: relative;
      text-align: left;
    }

    .selectedValue {
      width: 10vw;
      padding: 15px 10px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: white;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
    }
    .selectedValue:hover {
      border: 1px solid #afacfe;
      box-shadow: inset 0 0 1px #afacfe;
    }

    .optionsContainer {
      width: 20vw;
      position: absolute;
      top: 100%;
      left: 0;
      max-width: 400px;
      max-height: 200px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: white;
      overflow-y: auto;
      z-index: 1000;
    }

    .option {
        padding: 10px;
        cursor: pointer;
    }

    .option:hover {
      background-color: #dcdbff;
      color: #515151;
    }

    .level0 {
        padding-left: 1rem;
    }

    .level1 {
        padding-left: 3rem;
    }

    .level2 {
        padding-left: 5rem;
    }

    .level3 {
        padding-left: 7rem;
    }

    /* Add more levels as needed */

    .tabsHeaderContainer {
      display: flex;
      justify-content: space-between;
      margin: 1rem 1rem 2rem 1rem;
      align-items: center;
    }
    
    .bankCardRow {
      display: flex;
      gap: 2vw;
    }

    .bankCard {
      width: fit-content;
      height: fit-content;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
      background-color: white;
      border-color: transparent;
    }
    .bankCard:hover {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75);
    }

    .tabHeading {
      padding: 0 1rem;
    }

    .fileInput {
      color: white !important;
    }
}
  
  