.wrapper {
  margin: 0;
  padding: 20px 28px 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 137px;
  --table-height: calc(
    100vh - var(--header-height) - var(--topRow-height) - 153px
  );
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.topRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.body {
  display: flex;
  justify-content: space-between;
}

.totalsCard {
  height: var(--topRow-height);
  margin: 0;
  width: 70%;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  color: #3b3f43;
  > div > div:first-child {
    border-right: 1px solid #f0f0f0;
  }
  > div > div:last-child {
    border-left: 1px solid #f0f0f0;
  }
}

.totalsCardSections {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 33.3%;
  padding: 0;
  margin: 0;
  text-align: right;
  h3 {
    color: #7372ff;
    width: 80px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #f3f3ff;
    color: #7372ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
  }
  .count {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.optionCard {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 30%;
  height: var(--topRow-height);
  margin: 0 0 0 16px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    z-index: 99;
  }

  .optionIconBackdrop {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left: 35px;
    top: 35px;
    background: #f6f7fa;
    opacity: 0.1;
  }
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
}

.tableCard {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 16px 0;
  width: 49.4%;
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 5px 24px;
}

.tableCardFilters {
  display: flex;
  padding: 5px 24px 16px 24px;
}

.table {
  height: var(--table-height);
  overflow: hidden;
  &::before {
    display: table;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #fff, transparent);
    z-index: 9 !important;
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.modal {
  display: flex;
}

.modalLeft {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
}

.modalRight {
  border-left: 1px solid #f0f0f0;
  padding: 24px;
  overflow: hidden;
}

.transactionCard {
  background-color: #f3f3ff;
  border-radius: 8px;
  padding: 16px;
  width: 249px;
  margin: 20px 0;
}

.payee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  > span {
    background-color: #eddfff;
    color: #a97be3;
    padding: 0px 4px;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

.payeeDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #9ba3ab;
  }
}

.transaction {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  margin: 4px 0;
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-weight: bold;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  padding: 8px 0;
}

.summaryText {
  margin-top: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #7372ff;
}
