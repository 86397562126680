.modal {
  > div > div {
    overflow-y: auto;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.refundType {
  width: 100%;
}

.refundTypeRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.addOnInput {
  > span > input {
    height: 44px;
  }
  width: 184px;
}

.addOn {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #82898f;
}
