.success {
  height: 80vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  background-color: white;
}

.extra {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background-color: white;
}
