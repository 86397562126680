.wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
}

.container {
  background: #fff;
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0 0.5rem;
  border-radius: 8px;
  transition: width 0.3s ease;
}

.switchColumn {
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  position: absolute;
  z-index: 10;
  align-items: center;
  top: 0;
}

.chatColumn {
  position: relative;
  flex-grow: 1;
  width: calc(100% - 10%); /* Adjusts based on FAQ panel size */
  height: 100%;
  transition: width 0.3s ease;
}

.faqColumn {
  width: 0; /* Default width of FAQ column is 0 */
  height: 100%;
  overflow-y: auto;
  background: white;
  padding: 2rem 1rem;
  transition: width 0.3s ease;
}

.faqColumn.expanded {
  width: 10%; /* Expands to 10% width when the FAQ is active */
}

.collapseControl {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.collapseIcon {
  margin-right: 0.5rem;
}

/* Pin button style */
.pinButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #1B1A95;
}
