.wrapper {
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.greetingsSection {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  margin: 0.5rem;
  padding: 25px;
  font-size: 1.1rem;
}

.container {
  background: #fff;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  margin: 0 0.5rem;
  border-radius: 8px;
}

.tableSection {
  height: 100%;
  overflow-y: scroll;
  padding: 1rem;
  background: white;
  margin: 0 10px;
}

.categorization {
    padding: 20px;
    background: #ffffff;
  }
  
  .confirmButton {
    margin-top: 20px;
    float: right;
  }

.bankAccountsColumn {
  display: flex;
  gap: 1.5vw;
  margin: 2rem 1rem;
}

.bankCardRow {
  display: flex;
  gap: 2vw;
}

.bankCard {
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-color: transparent;
}
.bankCard:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75);
}

.tabsHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 2rem 1rem;
}

.fileInput {
  color: white !important;
}
