.container {
  // padding: 4.5rem 0 0;
  padding: 2px 0 0;

  .greetingsSection {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.8);
      margin: 0.5rem;
      padding: 25px;
      font-size: 1.1rem;
  }

  .postedStatus {
    background-color: #32620E40; // Background color for completed status
    color: #32620E; // Text color for completed status
    padding: 4px 8px; // Padding around the text
    border-radius: 4px; // Rounded corners
  }
  
  .pendingStatus {
    background-color: #FBF55540; // Background color for pending status
    color: #E8D314; // Text color for pending status
    padding: 4px 8px; // Padding around the text
    border-radius: 4px; // Rounded corners
  }

  .innerContent {
    padding: 2rem;
    height: 100vh;
    background: white;
    border-radius: 8px;
    margin: 0.5rem;
  }

  .bankCard {
    height: fit-content;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    background-color: white;
    border: 1px solid black;
  }

  .greeting {
    h1 {
      margin-bottom: 20px; // Adjust as needed
    }
  }

  .heading {
    color: #82898F;
    text-align: left;
    font-size: small;
    margin-bottom: 20px; // Added margin for spacing
  }

  .totalBalanceCard, .bankCard, .statisticsCard, .actionCard {
    box-shadow: 0px 4px 4px 0px #0000000D;
    margin-bottom: 20px;
  }

  .totalBalanceCard {
    background-color: #F6F7FAB2;
    .balanceInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .amount {
        font-size: large;
        sup {
          font-size: smaller;
        }
      }
    }
  }

  .bankCard {
    background: linear-gradient(90deg, rgba(212, 234, 247, 0.7) 0%, rgba(182, 204, 216, 0.7) 100%);
    .bankInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        &:first-child {
          text-align: left;
          div {
            &:first-child {
              font-weight: bold;
            }
            &:last-child {
              color: #82898F;
            }
          }
        }
        &:last-child {
          .amount {
            font-size: large;
            sup {
              font-size: smaller;
            }
          }
        }
      }
    }
  }

  .statisticsCard {
    background-color: #F6F7FAB2;
    h3 {
      margin-bottom: 20px;
    }
    .chartContainer {
      max-width: 1000px; // Adjust the size of the doughnut chart
      margin: 0 auto;
      font-weight: bold !important;
    }
  }

  .actionCard {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    &:hover {
      background-color: #f0f0f0;
    }
  }

  .tableContainer {
    height: calc(100vh - 150px); // Adjust the height as needed
    overflow: auto;
  }

  .extendedSection {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;

    .extendedCard {
      border: 1px solid #EEF1F1;
      box-shadow: 0px 4px 4px 0px #0000000D;
      height: 200px; // Adjust height as needed
    }

    .cardContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .cardHeading {
        font-weight: bold;
        color: #82898F;
        padding: 10px;
        margin-bottom: 10px;
      }

      p {
        margin: 5px 0;
      }

      .flexRow {
        // display: flex;
        width: 100%;

        div {
          display: flex;
          text-align: left;
          justify-content: space-evenly;
          // flex-direction: column;
          // align-items: flex-start;
        }
      }

      .upgradePlan {
        .ant-card-body {
          padding: 0 !important;
        }
      }

      .ctaButton {
        margin-top: 10px;
        box-shadow: 0px 4px 4px 0px #0000000D;
      }
    }
  }

  :global(.ant-tree-title) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  :global(.ant-tree-treenode) {
    padding: 0;
  }
  
  .treeNodeMove {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;

    :global(.ant-tree-title) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .ant-tree {
      width: 100% !important;
    }
  }

  :global(.ant-tree-switcher) {
    padding: 4px 0;
    // margin: 0 10px 0 0;
  }
  :global(.ant-tree), :global(.ant-tree-node-content-wrapper), :global(.ant-tree-node-content-wrapper-normal) {
    width: 100%;
  }

  .custom-modal .ant-tree-node-selected {
    background-color: red; // Set your desired background color here
  }

  :global(.ant-tree-checkbox) {
    right: 15px;
  }
}

// Additional global styles or overrides can be added here

