.wrapper {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.sidebarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 17vw;
}

.sectionWrapper {
  width: 83vw;
  position: absolute;
  top: 0;
  right: 0;
  // padding: 0 0.5rem;
}
