.modal {
  > div > div {
    overflow-y: auto;
  }
}

.modalBody {
  display: flex;
  justify-content: space-between;
}

.modalBodyLeft {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 0 30px 0 0;
}

.modalBodyRight {
  display: flex;
  flex-direction: column;
  width: 65%;
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
  }
}

.previewCard {
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  margin: 18px 0 6px 0;
  > div {
    padding: 6px;
    margin: 0;
    height: 100%;
    width: 542px;
    display: flex;
    justify-content: space-between;
  }
}

.previewCardLeft {
  background-color: #616099;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 50%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 80px;
  margin-right: -28px;
}

.headerRow {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.paymentInfo {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div:first-child {
    font-size: 12px;
  }
  > div:last-child {
    display: flex;
    align-items: flex-start;
    > span:first-child {
      font-size: 10px;
    }
    > span:last-child {
      margin-left: 4px;
      font-weight: bold;
      font-size: 16px;
      line-height: normal;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  > div:first-child {
    color: #fff;
    font-size: 8px;
    margin-bottom: 4px;
    > span {
      margin: 0 4px;
    }
  }
  > div:last-child {
    color: #fff;
    opacity: 0.6;
    font-size: 8px;
    > span:first-child {
      border-right: 1px solid #e5e7eb;
      padding-right: 6px;
    }
    > span {
      margin: 0 3px;
    }
  }
}

.previewCardRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 16px 24px;
}

.formHeaderRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 8px;
  > div {
    > span:last-child {
      font-weight: bold;
      margin-left: 4px;
    }
  }
}

.buttonWrapper {
  > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    border: 1px solid #000;
    color: #000;
  }
  > button:first-child {
    width: 100%;
  }
  > button:last-child {
    background-color: #000;
    color: #fff;
  }
}

.payWith {
  margin: 18px 0 0 0;
  border-top: 1px solid #d1d5dd;
  > span:first-child {
    background: #fff;
    position: absolute;
    left: 75%;
    top: 175px;
    padding: 0 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 15px;
    color: #9ba3ab;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.optionTxtButton {
  margin-right: 5px;
  padding: 0.5px;
  width: 100%;
  border: 1px solid #d1d5dd;
  &:hover {
    color: #7372ff;
  }
  &:active {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
  &:focus {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
}

.optionRButton {
  margin-right: 5px;
  padding: 18px 33px;
  width: 100%;
  border: 1px solid #d1d5dd;
  &:hover {
    color: #7372ff;
  }
  &:active {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
  &:focus {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
}

.avatarRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 16px 0;
  > span {
    margin-right: 12px;

    > img {
      object-fit: contain;
    }  
  }
  > button {
    color: #82898f;
    border: 1px solid #e5e7eb;
  }
}

.settingsPreview {
  -ms-zoom: 0.7;
  -moz-transform: scale(0.7);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.7);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.7);
  -webkit-transform-origin: 0 0;
  width: 750px;
  height: 780px;
  pointer-events: none;
}
