.modal {
  > div > div {
    overflow-y: auto;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}
