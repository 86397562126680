.modal {
  > div > div {
    overflow-y: auto;
  }
}

.transactionCard {
  background-color: #f3f3ff;
  border-radius: 8px;
  padding: 16px;
  width: 249px;
  margin: 20px 0;
}

.modalLeft {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 50%;
}

.modalRight {
  padding: 24px;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  width: 50%;
}
.paymentMethod {
  display: inline-flex;
  justify-content: space-between;
  width: 250px;
}
.checkbox {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.confirmContainer {
  margin: auto;
  width: auto;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  > span {
    color: #82898f;
    text-align: center;
    font-size: 12px;
  }
}
.inputAmount {
  > span {
    margin-left: 90px;
    color: black;
    border-right: none;
  }

  > input {
    background-color: #f6f7fa;
    font-size: 24px;
    font-weight: 700;
    border: 1px solid #ffffff;
  }
}
.shareSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  width: 360px;
  > span {
    color: #000;
  }
}
.shareWrapper {
  text-align: center;
  margin-top: 30px;
}

.bottomBar {
  display: inline-flex;
  justify-content: space-between;
  width: 363px;
  align-self: center;
  margin-top: 15px;
}
.formWrapper {
  display: flex;
}
.wrapper {
  margin: 50px 25px 50px 25px;
  justify-items: center;
}
.header {
  margin: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    text-align: right;
    line-height: 10px;
  }
}
.modalDetailsContainer {
  border-radius: 8px;
  width: 386px;
  margin-bottom: 16px;
  padding: 16px;
  > h4 {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
  }
  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: left;

    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #3b3f43;
      width: 70%;
    }
  }
}
.label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

