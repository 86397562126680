.input {
  &:hover {
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  }
  &:active {
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  }
  &:focus {
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  }
  &:disabled {
    border: none;
    box-shadow: none; 
  }
}

.default {
  height: 54px;
  padding: 8px 11px; 
}

.small {
  height: 44px; 
  padding: 8px 11px !important; 

}

