.infoWrapper {
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #3b3f43;
  }
  > div {
    margin-bottom: 40px;
    > div > div {
      margin-bottom: 8px;
      > div:first-child {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #82898f;
      }
      > div:last-child {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #3b3f43;
      }
    }
  }
  > div:last-child {
    > div > div:first-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > div > div:first-child {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #82898f;
        margin-right: 120px;
      }
      > div > div:last-child {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #3b3f43;
      }
    }
  }
}

.formWrapper {
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #3b3f43;
  }
  form {
    > h3:first-child {
      margin: 0 0 24px 0;
    }
  }
}

.label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #3b3f43;
  margin-bottom: 0;
}

.mobileInputPrefix {
  > div {
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Styles */
  .formWrapper {
    padding-bottom: 0px;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .formWrapper {
    padding-bottom: 0px;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .formWrapper {
    padding-bottom: 0px;
  }
}
