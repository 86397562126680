.wrapper {
  margin: 0 auto;
  width: 85%;
  height: 100vh;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 46px 0;

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > div {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      margin-right: 24px;
    }
  }
  > div:last-child {
    text-align: right;

    > div:first-child {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
    > div:not(:first-child):not(:last-child) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      word-break: break-all;
      word-wrap: break-word;
    }
    > div:last-child {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}

.logo {
  width: 67px;
  height: 67px;
  background-color: #fff;

  > img {
    object-fit: contain;
  }
}

.companyInfo {
  span {
    font-weight: 600;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 8px;
    }
  }
  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.paymentCard {
  > div {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}

.paymentCardInfo {
  background-color: #f3f3ff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 34px 32px;
  flex: 1;
}

.paymentCardForm {
  flex: 2;
}

.paymentAmount {
  padding: 36px 24px;
  background: #f6f7fa;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #3b3f43;
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    > div:first-child {
      display: flex;
      align-items: flex-start;
      margin-bottom: 5px;
      > span:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #3b3f43;
        margin-right: 6px;
      }
      > span:last-child {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        color: #3b3f43;
      }
    }
    > div:last-child {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #82898f;
    }
  }
}

.amountInput {
  width: 124px;
  height: 35px;
  padding: 0 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  color: #3b3f43;
  > input {
    width: 105px;
    height: 35px;
    padding: 0 3px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #3b3f43;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Styles */
  .footer {
    flex-direction: column;

    > div:first-child {
      margin-bottom: 10px;
    }
    > div:last-child {
      margin-bottom: 90px;
    }
  }
  .header {
    align-items: flex-start;
    margin: 46px 0 24px 0;
    > div:first-child {
      flex-direction: column;
      align-items: flex-start;
      > div {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
        width: 100%;
      }
    }
  }
  .logo {
    width: 36px;
    height: 36px;
  }
  .wrapper {
    width: 95%;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .footer {
    flex-direction: column;
    > div:first-child {
      margin-bottom: 10px;
    }
    > div:last-child {
      margin-bottom: 90px;
    }
  }
  .header {
    align-items: flex-start;
    margin: 46px 0 24px 0;
    > div:first-child {
      flex-direction: column;
      align-items: flex-start;
      > div {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
        width: 100%;
      }
    }
  }
  .logo {
    width: 36px;
    height: 36px;
  }
  .wrapper {
    width: 95%;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .footer {
    flex-direction: column;
    > div:first-child {
      margin-bottom: 10px;
    }
    > div:last-child {
      margin-bottom: 90px;
    }
  }
  .header {
    align-items: flex-start;
    margin: 46px 0 24px 0;
    > div:first-child {
      flex-direction: column;
      align-items: flex-start;
      > div {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
        width: 100%;
      }
    }
  }
  .logo {
    width: 36px;
    height: 36px;
  }
  .wrapper {
    width: 95%;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
  .paymentCard {
    > div {
      height: calc(100vh - 250px);
    }
  }
  .paymentCardInfo {
    height: calc(100vh - 250px);
    overflow-y: auto;
  }
  .paymentCardForm {
    height: calc(100vh - 250px);
    overflow-y: auto;
    padding: 34px 100px;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
  .paymentCard {
    > div {
      height: calc(100vh - 250px);
    }
  }
  .paymentCardInfo {
    height: calc(100vh - 250px);
    overflow-y: auto;
  }
  .paymentCardForm {
    height: calc(100vh - 250px);
    overflow-y: auto;
    padding: 34px 100px;
  }
}

/* iPhone 4 ----------- */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  /* Styles */
}
