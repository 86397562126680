.container {
  // padding: 4.5rem 0 0;
  padding: 2px 0 0;

  .greetingsSection {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.8);
      margin: 0.5rem;
      padding: 25px;
      font-size: 1.1rem;
  }
}

// Additional global styles or overrides can be added here

