.greetingsSection {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    margin: 0.5rem;
    padding: 25px;
    font-size: 1.1rem;
}

/* Styles */

.container {
  // padding: 20px;
}

.innerContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card {
  border: 1px solid #eef1f1;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  padding: 20px;
  margin: 0.5rem;
}

.noDataOverlay {
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  color: #1d1c1c80;
  font-size: 1vw;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.gridCashBurn {
  display: grid;
  grid-template-columns: 50% 50%; /* Define column widths */
  gap: 20px; /* Optional: Add spacing between columns (optional) */
}
.gridCashflow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.gridPl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.centeredCard {
  text-align: center;
  border: 1px solid #eef1f1;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  padding: 20px;
}

.flexBox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cardText {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

:global(p) {
  margin: 0;
}
:global(.ant-tabs) {
  background: white;
  margin: 0 10px;
  font-size: 1rem;
}
:global(.ant-tabs-nav) {
padding: 0 20px;
}
:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #000000;
  font-weight: bold;
  font-size: 1rem;
  text-shadow: 0 0 0.25px currentcolor;
}
:global(.ant-tabs-ink-bar) {
  position: absolute;
  background: #000000;
  pointer-events: none;
}

.chartContainer {
  max-height: 300px; // Adjust the size of the doughnut chart
  margin: 0 auto;
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  height: 80%;
  align-items: center;
}

.runwayLegend {
  display: flex;
  height: fit-content;
  width: 100%;
  position: relative;
  top: 40px;
}

// style chartContainer when screen width is less than 1550px
@media screen and (max-width: 1550px) {
  .revenueChart  {
    height: auto;
  }

  .runwayLegend {
    display: flex;
    height: fit-content;
    width: 100%;
    position: relative;
    top: 0px;
  }
}

.plTables {
  display: flex;
  flex: 1 0;
  // justify-content: space-between;
  gap: 20px;
  padding: 30px;
  flex-direction: column;
}

.tableContainer {
  text-align: left;
}

/* Misc */

.inviteButton {
  background: linear-gradient(92.79deg, #7372FF 8.36%, #9772FF 92.75%);
  width: 183.45px;
  height: 44px;
  border-radius: 8px;
  float: right;
}


// label {
//   margin: 0 !important;
//   width: 50px !important;
// }

// .ant-form {
//   label {
//     margin: 0 !important;
//     // width: fit-content !important;
//   }
// }

.ant-form-item {
  margin-bottom: 20px !important;
}

.rolesPermissions {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0.1rem 0rem 0;

  .columnTitles {
    display: flex;
    justify-content: space-between;
  }

  .separator, .middleSeparator {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .columnsContainer {
    display: flex;
    width: 65%;

    .bankAccountsColumn, .quickBooksColumn {
      flex: 1;
    }

    .middleSeparator {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 0;
      height: 70vh;
    }

    .bankAccountRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .disconnectButton, .connectedButton {
      border: 1px solid black;
      color: black;
    }

    .addBankAccount {
      text-align: right;
    }

    .quickBooksContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .connectedButton {
      background-color: green;
      border-color: green;
      color: white;
    }
  }

  .columnTitles {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 20vw;
  }

  .roleTitle, .permissionsTitle {
    font-weight: bold;
  }

  .separator {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }

  .content {
    display: flex;
    height: 100%;

    .rolesColumn, .permissionsColumn {
      flex: 1;
    }

    .verticalSeparator {
      width: 1px;
      background-color: #ccc;
      margin: 0 20px;
    }

    .rolesColumn {
      .ant-tabs-tabpane {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

// @media screen and (max-width: 1400px) {
//   .chartContainer {
//     height: initial;
//   }
// }


.content {
  :global(.ant-tabs-nav-list) {
    margin: 0 2rem 0 0 !important;
    width: 20vw !important;
  }
  
  :global(.ant-tabs-ink-bar) {
    width: 100% !important;
    z-index: -1 !important;
    border-radius: 2px !important;
    border: 0.5px solid #7372FF !important;
    background: rgba(246, 247, 250, 0.70) !important;
  }
}
