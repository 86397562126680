.wrapper {
  margin: 0;
  padding: 20px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --topRow-height: 138px;
  --bottomRow-height: 127px;
  --table-height: calc(
    100vh - var(--header-height) - var(--topRow-height) -
      var(--bottomRow-height) - 175px
  );
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.topRow {
  // max-height: var(--topRow-max-height);
  // height: var(--topRow-height);
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  > div:first-child {
    width: 49.3%;
  }
  > div:last-child {
    width: 49.3%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
}

.bottomRow {
  // height: var(--bottomRow-height);
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.body {
  display: flex;
  justify-content: space-between;
}

.valueCard {
  // height: 137px;
  // max-height: var(--topRow-max-height);
  height: var(--topRow-height);
  margin: 0;
  width: 100%;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  color: #3b3f43;
}

.sentInvoices {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 33.3%;
  text-align: right;
  h3 {
    color: #7372ff;
    width: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #f3f3ff;
    color: #7372ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
  }
}

.paidInvoices {
  border-left: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 33.3%;
  padding: 0;
  margin: 0;
  text-align: right;
  h3 {
    color: #1dbc83;
    width: 60px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #e1fff4;
    color: #1dbc83;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
  }
}

.overdueInvoices {
  border-left: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 33.3%;
  padding: 0;
  margin: 0;
  text-align: right;
  h3 {
    color: #ee6969;
    width: 60px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #fffcf1;
    color: #bc7c1d;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
  }
}

.optionCard {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  width: 31.5%;
  // max-height: var(--topRow-max-height);
  height: var(--topRow-height);
  // height: 137px;
  // margin: 0 0 0 16px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    z-index: 99;
  }

  .optionIconBackdrop {
    position: absolute;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: 17px;
    top: 17px;
    background: #f6f7fa;
    opacity: 0.1;
  }
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    span {
      width: 82px;
    }
  }
}

.optionCardLight {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  width: 19%;
  background-color: #3b3f43;
  // max-height: var(--topRow-max-height);
  height: var(--topRow-height);
  // height: 137px;
  // margin: 0 0 0 16px;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    z-index: 99;
  }
  .optionCardComingSoonLabel {
    color: #d3b25a;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 150%;
  }
  .optionIconBackdropLight {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left: 35px;
    top: 35px;
    background: #f6f7fa;
  }
  .arrowBtnWithBg {
    color: #fff;
    background-color: #7372ff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    z-index: 999;
    color: #3b3f43;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    span {
      width: 82px;
    }
  }
}

.tableCard {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 16px 0;
  width: 49.3%;
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 5px 24px;
}

.tableCardFilters {
  display: flex;
  padding: 5px 24px 16px 24px;
}

.table {
  margin-left: 20px;
  height: var(--table-height);
  overflow: hidden;
  tbody > tr:hover {
    cursor: pointer;
  }
  &::before {
    display: table;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #fff, transparent);
    z-index: 9 !important;
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  :global {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      overflow-wrap: anywhere;
    }
  }
}

.modal {
  > div > div {
    overflow-y: auto;
  }
}

.modalHeader {
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3b3f43;
    margin-bottom: 16px;
  }
}

.modalBodyFormUpper {
  display: flex;
  justify-content: flex-start;
  gap: 190px;
}

.invoiceSummary {
  width: 270px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #3b3f43;
    }
  }
  > div:last-child {
    border-bottom: 3px double #e5e7eb;
    > span {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
  .total {
    border-top: 1px double #e5e7eb;
    border-bottom: 1px double #e5e7eb;
    > span {
      margin: 10px 0;
    }
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}
.customSelect {
  :global {
    .ant-select-selector {
      padding-right: 30px !important;
    }
  }
}

.saleItemsWrapper {
  margin: 35px 0;
  width: 100%;
}

.inputGroup {
  > div > div > div > div {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
}

.inputGroupRemoveIcon {
  color: #9ba3ab;
  margin: 0 10px 7px 3px;
  cursor: pointer;
}

.addNewButton {
  display: inline-flex;
  align-items: center;
  color: #7372ff;
}

.salesItemsHeader {
  width: 100%;
  display: inline-flex;
  // justify-content: space-evenly;
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #9ba3ab;
    margin-right: 5px;
  }
  // > span:first-child {
  //   margin-left: 32px;
  // }
}

.salesItemsFooter {
  margin: 0 0 0 14px;
}

.salesItemSelect {
  > div {
    border-radius: 0 !important;
  }
  :global {
    .ant-select-selector {
      padding-right: 42px !important;
    }
  }
}

.statusSelect {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  height: 16px !important;
  > div::after {
    line-height: 12px !important;
  }
  > div > span:last-child {
    line-height: 12px !important;
  }
  > span {
    display: flex;
    align-items: center;
  }
  :global {
    .ant-select-selector {
      height: auto !important;
      padding: 0 !important;
    }
  }
}

.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}

.inputNumber {
  height: 44px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 0;
  > div:last-child > input {
    height: 42px;
    background-color: transparent;
  }
}

.autoSelectDesc {
  > div {
    height: 44px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
