.profileComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 0 0 15vh 0;
    // height: 100vh;
  
    .profileForm {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
  
      .heading {
        text-align: center;
        margin-bottom: 24px;
      }
  
      .formItem {
        margin-bottom: 16px;
      }
  
      .ownerLabel {
        display: block;
        margin-bottom: 8px;
      }
  
      .radioGroup {
        display: flex;
        justify-content: space-between;
      }
  
      .submitButton {
        width: 100%;
      }
    }
  }
  
.signupFormInputs {
    margin: 15px 0;
}