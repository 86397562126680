.otpWrapper {
  width: 52%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otp {
  padding-left: 21px;
  width: 54px;
}

.options {
  display: flex;
  justify-content: space-between;
  p {
    color: #3f63e2;
  }
}

.otpSignIn {
  height: 120px;
  padding: 16px 20px 16px 16px;
  background-color: #fcfdff;
  div {
    font-size: 14px;
    font-weight: 600;
    color: #3b3f43;
  }
  p {
    height: 66px;
    color: #9ba3ab;
    font-weight: 400;
    font-size: 14px;
    padding: 0 24px 16px 28px;
  }
}

.inputsWrapper {
  // display: flex;
  flex-direction: row;
  margin: 16px 0;
}
