.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chartWrapper {
  width: 65%;
  padding: 30px 0 30px 22px;
}

.chart {
  height: calc(var(--tabPane-height) - 75px);
}

.itemsWrapper {
  width: 35%;
  height: var(--tabPane-height);
  padding: 30px 22px 30px 42px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
}

.item {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f6f7fa;
  border-radius: 8px;
}

.itemHeading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.heading {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1dbc83;
}

.iconContainer {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  background-color: #e1fff4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.count {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #3b3f43;
}

.itemFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.trend {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #82898f;
}

.chartName {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3b3f43;
}

.hint {
  border-radius: 50px;
  background-color: #e1fff4;
  p {
    margin: 0px;
    line-height: 2px;
    padding: 8px;
    color: #1dbc83;
    display: flex;
    font-size: 10px;
  }
}