// UploadTaxReturns.module.scss

.profileComponent {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
}

.profileComponent p {
  font-size: 16px;
  margin-bottom: 10px;
}

.uploadButtonContainer {
  margin-top: 20px;
}

.uploadContainer {
  margin: 2rem 0;

  .uploadRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .uploadColumn {
    display: flex;
    align-items: center;

    .uploadLabel {
      font-weight: 600;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      
      .infoIcon {
        margin-left: 0.3rem !important;
        color: #8c8c8c;
        cursor: pointer;
      }
    }
  }

  .uploadButtonColumn {
    width: 35%;

    .upload-button {
      color: black;
      border: 1px solid black !important;
    }
  }
}

.uploadInstruction {
  margin-top: 1rem;
  font-size: 14px !important;
  color: #595959;
}

.taxUpload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem;
  flex-direction: column;
}

.uploadContainer .ant-upload.ant-upload-select-picture-card {
  width: 100%;
}

.uploadContainer .ant-upload-list-item {
  display: flex;
  align-items: center;
}

.uploadContainer .ant-upload-list-item-name {
  flex: 1;
}

.uploadContainer .ant-upload-list-item-thumbnail {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.ctaButton {
  margin-top: 20px;
}

.ctaButton .ant-btn {
  width: 100%;
}
