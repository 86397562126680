.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%
}

.logo{
    height: 81px;
    width: 215px;
    margin-bottom: 80px;
}

.description{
    width: 50%;
    text-align: center;
}

.button{
   width: 50%;
   height: 48px;
   margin-top: 32px;
}