.modal {
  > div > div {
    overflow-y: auto;
  }
}
.ant-input-group {
  padding-left: 70%;
}

.formItems {
  display: flex;
  flex-direction: row;
}
.modalHeader {
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3b3f43;
    margin-bottom: 16px;
  }
}

.modalBodyFormUpper {
  display: flex;
  justify-content: flex-start;
  gap: 190px;
}

.invoiceSummary {
  width: 270px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #3b3f43;
    }
  }
  > div:last-child {
    border-bottom: 3px double #e5e7eb;
    > span {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
  .total {
    border-top: 1px double #e5e7eb;
    border-bottom: 1px double #e5e7eb;
    > span {
      margin: 10px 0;
    }
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.saleItemsWrapper {
  margin: 35px 0;
  width: 100%;
}

.inputGroup {
  > div > div > div > div {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
}

.inputGroupRemoveIcon {
  color: #9ba3ab;
  margin: 0 10px 7px 3px;
  cursor: pointer;
}

.addNewButton {
  display: inline-flex;
  align-items: center;
  color: #7372ff;
}

.salesItemsHeader {
  width: 100%;
  display: inline-flex;
  // justify-content: space-evenly;
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #9ba3ab;
    margin-right: 5px;
  }
}

.salesItemsFooter {
  margin: 0 0 0 14px;
}

.salesItemSelect {
  > div {
    border-radius: 0 !important;
  }
  :global {
    .ant-select-selector {
      padding-right: 42px !important;
    }
  }
}

.frequencySelect {
  width: 168px;
  > div > span::before {
    content: "Every ";
    color: #82898f;
    font-size: 12px;
    margin-right: 15px;
  }
}

.autoSelectDesc {
  > div {
    height: 44px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
