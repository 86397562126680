.wrapper {
  margin: 0;
  // display: flex;
  // height: 100%;
  // justify-content: space-between;
  flex: 1;                     /* Take up all remaining space */
  // padding: 20px;               /* Example padding */
  // overflow-y: auto;            /* Enable scrolling if content exceeds the available height */
  // background-color: #fff;      /* Example background color */
}

// .sidebarWrapper {
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   width: 17vw;
// }

.sectionWrapper {
  // width: 83vw;
  // position: absolute;
  // top: 0;
  height: 100%;
  // right: 0;
  // padding: 0 0.5rem;
  background-color: #F6F7FA;
}
