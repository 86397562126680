.modal {
  display: flex;
}

.modalLeft {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 455px;
}

.modalRight {
  border-left: 1px solid #f0f0f0;
  padding: 24px;
  overflow: hidden;
  width: 350px;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3b3f43;
  }
  display: flex;
  flex-direction: column;
  ul {
    margin: 10px 60px 10px 15px;
    padding: 0;
  }
  li {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #9ba3ab;
    margin-bottom: 10px;
  }
}
.uploadCard {
  position: relative;
  border: 1px solid #9f9cff;
  height: 120px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  transition: 0.5s;

  > div :last-child {
    width: 150px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #9f9cff;
  }
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 16px;
  color: #9ba3ab;
}
.uploadCard:hover {
  background-color: #edeaec;
  transition: 0.5s;
}
.hideControl {
  display: none;
}
.hasFile {
  margin-bottom: 0!important;
  
  & > :global(.ant-form-item-control) {
    display: none;
  }
}
