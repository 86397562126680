.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  text-align: left;
  padding: 28px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 83%;
  background-color:  #f6f7fa;

  ;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    color: #3b3f43;
    margin: 8px 0px;
  }
}

.headerName {
  display: flex;
  align-items: center;
}

.buttonWrapper {
  width: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerRight {
  display: inline-flex;
  align-items: center;
  // > div:first-child {
  //   margin-right: 18px;
  // }
}

.enableOnlinePayments {
  display: inline-flex;
  align-items: center;
  > span {
    margin-right: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #3b3f43;
  }
}
