.wrapper {
  margin: 0;
  padding: 20px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --settingsHomeCard: calc(100vh - var(--header-height) - 345px);
  --settingsTopicCard: calc(
    100vh - var(--header-height) - var(--settingsHomeCard) - 215px
  );
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  justify-content: space-between;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.settingsHomeCard {
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    margin: 0;
    height: var(--settingsHomeCard);
  }
  background: #7372ff;
  color: #fff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  text-align: center;
  margin-bottom: 24px;
  h3 {
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
  }
  > div > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;
    padding: 50px 100px;
    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
  > div > div:last-child {
    border-left: 1px solid rgb(229, 231, 235, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    padding: 50px 100px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      margin-top: 16px;
    }
  }
}

.supportPill {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 6px 6px 13px;
  margin: 25px 0;
  width: 212px;
  height: 52px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  text-align: left;
  > div {
    display: flex;
    flex-direction: column;
  }
  span {
    margin-right: 15px;
  }
}

.searchTopic {
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  margin-top: 50px;
  // width: 55%;
  > span {
    margin-right: 15px;
  }
}

.topicsWrapper {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 100%;
  height: 100%;
  .supportTopicCard {
    cursor: pointer;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      &:before {
        content: none !important;
      }
      &:after {
        content: none !important;
      }
    }
    flex-basis: 100%;
    flex: 1 25%;
    margin: 8px;
    height: var(--settingsTopicCard);
    background: #fff;
    border: none;
    box-sizing: border-box;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  }
  .supportTopicCardDropdown {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    flex-basis: 100%;
    flex: 1 25%;
    margin-bottom: 16px;
    width: auto;
    height: var(--settingsTopicCard);
  }
}

.supportTopicCard:hover {
  > div {
    .arrowBtnWithoutBg {
      transform: rotate(180deg);
      color: #7372ff;
    }
    .arrowBtnWithBg {
      transform: rotate(-90deg);
      color: #fff;
      transition: transform 0.7s ease-in-out;
    }
  }
}

.supportTopicCardDisabled {
  span {
    color: #9ba3ab;
  }
  .topicAction {
    svg {
      background-color: #e9edf4;
    }
  }
  .comingSoon {
    color: #d3b25a;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 150%;
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
.topicAction {
  color: #3b3f43;
  font-weight: 700;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  span {
    width: 70px;
  }
}

.topicIcon {
  z-index: 99;
}

.topicIconBackdrop {
  position: absolute;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  left: 35px;
  top: 35px;
  background: #f3f3ff;
}
.menuWrapper {
  background-color: transparent;
  box-shadow: none;
}
.menuItem {
  height: 48px;
  width: 280px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #7372ff;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(108, 99, 255, 0.3);
  &:hover {
    box-shadow: 0px 4px 10px 3px rgba(108, 99, 255, 0.4);
  }
  > span {
    font-size: 12px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  .arrowBtnWithoutBg {
    color: #fff;
    background-color: #7372ff;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
  }
  .menuItemTitle {
    display: inline-flex;
    align-items: center;
    > span {
      margin-left: 16px;
    }
  }
}

.arrowBtnWithBg {
  color: #fff;
  background-color: #7372ff;
  border-radius: 50%;
  cursor: pointer;
}

.arrowBtnWithBgFixed {
  width: 32px;
  height: 32px;
  color: #fff;
  background-color: #7372ff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
