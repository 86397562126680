.wrapper {
  margin: 0;
  padding: 28px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.spinWrapper {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body {
  display: flex;
  justify-content: space-between;
}

.invoiceCard {
  background: #e5e7eb;
  width: 49.3%;
  > div {
    display: flex;
    flex-direction: column;
    padding: 25px 43px;
    margin: 0;
    height: 83vh;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
}

.editBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #7372ff;
    margin-left: 6px;
  }
}

.zoomBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #3b3f43 !important;
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    margin-left: 6px;
  }
}

.invoiceContainer {
  position: relative;
  overflow: hidden;
  width: 70%;
  height: 94%;
  padding: 0;
  margin: 0 auto;
}

.invoice {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.invoiceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 21px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    color: #3b3f43;
  }
}

.invoiceOptions {
  margin-top: 21px;
  display: flex;
  justify-content: flex-end;
}

.modalHeader {
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3b3f43;
    margin-bottom: 16px;
  }
}

.label {
  margin: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.switchRow {
  > div > div > div {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  > div > div > div > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:first-child {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #3b3f43;
    }
    span:last-child {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #9ba3ab;
    }
  }
}

.stepper {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  > span {
    width: 49.7%;
    border-top: 6px solid #7372ff;
  }
}

.modalBody {
  padding: 24px;
}

.inactiveLabel {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
