.customerData {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0;
}

.header {
  background-color: #ffffff;
  color: #fff;
  padding: 16px;
  text-align: center;
}

.content {
  flex: 1;
//   padding: 24px;
//   overflow-y: auto;
}

.tabs {
  height: 100%;
  margin: 0 !important;
}
