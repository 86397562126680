.wrapper {
  margin: 0;
  // display: flex;
  // justify-content: space-between;
  // height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  height: 100%;               /* Full viewport height */
}

.sidebarWrapper {
  // position: fixed;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // width: 17vw;

  flex: 0 0 17vw;             /* Fixed width for sidebar, but still responsive */
  height: 100vh;               /* Full height of the parent */
  position: sticky;            /* Keeps sidebar in a fixed-like position */
  top: 0;                      /* Stick to the top */
}

.columnGroup {
  display: flex;
  flex-direction: row;
}

.group1,
.group2 {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
}

.logoBox,
.sidebarBox {
  width: 250px; // Default width for both
  flex-shrink: 0; // Prevent logoBox from shrinking
}

.greetingBox,
.contentBox {
  flex-grow: 1;
}

.logoBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebarBox {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: width 0.3s ease;
  
  // Sidebar shrinking/expanding behavior
  &.collapsed {
    width: 80px; // Shrink width when collapsed
  }
  
  &.expanded {
    width: 250px; // Default expanded width
  }
}

.greetingBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // background: white;
}

.contentBox {
  flex-grow: 1;
  display: flex;
  // padding: 20px;
  border-radius: 25px;
  overflow: auto;
}

.logo {
  width: -moz-fit-content;
  // width: 100%;
  min-width: 250px;
  height: 91px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  padding: 1.5rem;
  background: #000000;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: space-evenly;

  img {
    object-fit: contain;
    width: 80%;
  }

  // @media (max-width: 1200px) {
  //   width: 140px;
  // }
}

.greetingsSection {
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  margin: 10px;
  padding: 0 25px;
  flex: 0 0 auto;
  font-size: 1.1rem;
  height: 91px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;


  .menuContainer {
    position: relative;
    display: flex;
    width: 180px;
    height: fit-content;
    align-items: center;
  }

  .menu {
    position: absolute;
    top: -25px;
    right: 0;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-width: 200px;
  }

  .menuContainer:hover .menu {
    display: block; // Show when hovering over the container
  }
}

.sectionWrapper {
  // width: 83vw;
  // position: absolute;
  // top: 0;
  // right: 0;
  // background-color: #f6f7fa;
  height: 100%;
  display: flex;               /* Enable Flexbox for its children */
  flex-direction: column;      /* Stack children vertically */
  flex: 1;                     /* Take up the remaining space */
  // padding: 20px;               /* Add padding for internal spacing */
  overflow-y: auto;            /* Enable vertical scroll if content overflows */
  background-color: #f6f7fa;   /* Example background color */
}

.appUi {
  flex: 1;                     /* Take up all remaining space */
  padding: 20px;               /* Example padding */
  overflow-y: auto;            /* Enable scrolling if content exceeds the available height */
  background-color: #fff;      /* Example background color */
}

.footer {
  padding-left: 33px;
  padding-bottom: 35px;
  background: white;
  margin: 0 0 0 10px;
  
  :global {
    .ant-btn-round {
      height: auto !important;
    }

    .ant-btn {
      height: auto !important;
      border: none !important;
      padding: 2px !important;
    }
  }
}

.menu {
  color: #9ba3ab;
  border-right: none !important;
  padding-left: 20px !important;
  flex: 1 1;
  margin: 0 10px !important;
  background-color: transparent;
}
.profileMenu {
  width: calc(100% - 25px);
  font-style: normal !important;
  box-shadow: none !important;
  color: #9ba3ab !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  padding: 6px !important;
  border-radius: 10px;
  gap: 10px;

  :global {
    .ant-menu-submenu-title {
      height: auto !important;
    }
  }

  li {
    padding: 0 !important;
    margin: 0 !important;
  }
}

// .greetingsSection {
//   display: flex;
//   justify-content: flex-end;
//   padding: 10px;
//   background-color: #f5f5f5;
//   border-bottom: 1px solid #ddd;
// }

// .greetingsControls {
//   display: flex;
//   align-items: center;
//   gap: 15px;
// }

// .businessDropdown {
//   cursor: pointer;
//   font-weight: bold;
//   color: #000;
// }

// .userName {
//   font-size: 16px;
//   color: #333;
// }

// .settingsIcon {
//   font-size: 20px;
//   cursor: pointer;
// }
