::-webkit-scrollbar {
  width:8px;
  height:8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffc7a2; 
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#c3c3c3; 
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff9752; 
}

.container {
  // padding: 4.5rem 0 0;
  // padding: 2px 0 0;
  // height: 100vh;
  display: flex;
  flex-direction: column;

  .postedStatus {
    background-color: #32620E40; // Background color for completed status
    color: #32620E; // Text color for completed status
    padding: 4px 8px; // Padding around the text
    border-radius: 4px; // Rounded corners
  }
  
  .pendingStatus {
    background-color: #FBF55540; // Background color for pending status
    color: #E8D314; // Text color for pending status
    padding: 4px 8px; // Padding around the text
    border-radius: 4px; // Rounded corners
  }

  .innerContent {
    padding: 2rem;
    // height: 88%;
    background: white;
    border-radius: 8px;
    margin: 0 10px;
    flex-grow: 1;
  }

  .greeting {
    h1 {
      margin-bottom: 20px; // Adjust as needed
    }
  }

  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
  }

  .heading {
    color: #1D1C1C;
    text-align: left;
    font-size: 1rem;
    margin: 0;
    // font-weight: medium;
    // margin-bottom: 20px; // Added margin for spacing
  }

  .totalBalanceCard, .bankCard, .statisticsCard, .actionCard {
    box-shadow: 0px 4px 4px 0px #0000000D;
    margin-bottom: 20px;
  }

  .totalBalanceCard {
    background-color: #F6F7FAB2;
    .balanceInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .amount {
        font-size: large;
        sup {
          font-size: smaller;
        }
      }
    }
  }

  .bankCard {
    background: linear-gradient(90deg, rgba(212, 234, 247, 0.7) 0%, rgba(182, 204, 216, 0.7) 100%);
    .bankInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        &:first-child {
          text-align: left;
          div {
            &:first-child {
              font-weight: bold;
            }
            &:last-child {
              color: #82898F;
            }
          }
        }
        &:last-child {
          .amount {
            font-size: large;
            sup {
              font-size: smaller;
            }
          }
        }
      }
    }
  }

  .statisticsCard {
    background-color: #F6F7FAB2;
    h3 {
      margin-bottom: 20px;
    }
    .chartContainer {
      max-width: 1000px; // Adjust the size of the doughnut chart
      margin: 0 auto;
      font-weight: bold !important;
    }
  }

  .actionCard {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px;
    &:hover {
      background-color: #f0f0f0;
    }
  }

  .onboardingCard {
    margin: 1rem 1rem 3rem 1rem;
    font-weight: 600;
    color: #82898F;
    text-align: center;
    font-size: 1rem;
  }

  .tableContainer {
    height: calc(100vh - 150px); // Adjust the height as needed
    overflow: auto;
  }

  .extendedSection {
    // margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;

    .extendedCard {
      border: 1px solid #EEF1F1 !important;
      box-shadow: 0px 4px 4px 0px #0000000D !important;
      height: 100% !important; // Adjust height as needed
    }

    .cardContent {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      text-align: left;
      // height: 150px;

      .cardHeading {
        font-weight: bold;
        color: #82898F;
        padding: 10px;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 1500px) {
        .cardHeading {
          font-size: 0.96rem;
          padding: 10px 10px 0 10px;
        }
      }

      p {
        margin: 5px 0;
      }

      .flexRow {
        // display: flex;
        width: 100%;
        text-align: left;

        div {
          display: flex;
          // text-align: left;
          text-align: center;
          justify-content: space-evenly;
          // flex-direction: column;
          // align-items: flex-start;
        }
      }

      @media screen and (max-width: 1100px) {
        .flexRow {
          font-size: 0.7rem;
        }
      }

      .upgradePlan {
        .ant-card-body {
          padding: 0 !important;
        }
      }

      .ctaButton {
        margin-top: 10px;
        box-shadow: 0px 4px 4px 0px #0000000D;
      }
    }
  }
}

// Additional global styles or overrides can be added here
/* Custom Steps */

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #218737 !important;
}



/* Picked from Insights */

.card {
  border: 1px solid #eef1f1;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  padding: 20px;
  margin: 1rem 0;
}

.noDataOverlay {
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  color: #1d1c1c80;
  font-size: 1vw;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.gridCashBurn {
  display: grid;
  grid-template-columns: 50% 50%; /* Define column widths */
  gap: 20px; /* Optional: Add spacing between columns (optional) */
}
.gridCashflow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.gridPl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.centeredCard {
  text-align: center;
  border: 1px solid #eef1f1;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  padding: 20px;
}

.flexBox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cardText {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
