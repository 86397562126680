.wrapper {
  margin: 0;
  padding: 20px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --balanceCard-height: 16vh;
  --balanceCard-max-height: 137px;
  --paymentCard-height: 127px;
  --paymentOption-height: calc(
    (100vh - var(--header-height) - var(--balanceCard-max-height) - 60px) / 3
  );
  --scheduledPayment-height: calc(
    100vh - var(--header-height) - var(--balanceCard-max-height) -
      var(--paymentCard-height) - 110px
  );
}

.typeTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  background: #fff5d3;
  border-radius: 2px;
  color: #c9a11a;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.payeeSelection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #3b3f43;
  font-size: 12px;
}
.payeeSelectionDetails {
  font-size: 10px;
  color: #9ba3ab;
  line-height: 12.1px;
}

.refreshBtn {
  background-color: #f3f3ff !important;
  color: #7372ff !important;

  span {
    margin-left: 5px !important;
    margin-top: 5px !important;
  }

  &:active {
    border: none !important;
  }
  &:focus {
    border: none !important;
  }
}

.layout {
  padding-top: 80px;
}

.tableWrapper {
  width: 53vw;
}

.tableCard {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 16px 0;
}

.table {
  min-height: 189px;
  height: var(--scheduledPayment-height);
  overflow: hidden;
  &::before {
    display: table;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #fff, transparent);
    z-index: 9 !important;
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.balanceCard {
  max-height: var(--balanceCard-max-height);
  height: var(--balanceCard-height);
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  color: #3b3f43;
  .cardRight {
    border-left: 1px solid #f0f0f0;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
  }
}

.totalBalance {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
  .amount {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 36px;
      margin-left: 24px;
      margin-right: 5px;
    }
  }
}

.currentBalance {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-right: 1px solid #f0f0f0;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 50%;
  h3 {
    color: #1dbc83;
  }
  .icon {
    background-color: #e1fff4;
    color: #1dbc83;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
  }
}

.upcomingPayments {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  padding: 0;
  margin: 0;
  h3 {
    color: #7372ff;
  }
  .icon {
    background-color: #f3f3ff;
    color: #7372ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
  }
}

.bodyWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.paymentOptionsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  .paymentOption {
    margin: 16px 0 0 16px;
  }
  .comingSoonOption {
    margin: 16px 0 0 16px;
  }
}

.paymentOption {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  flex-basis: 100%;
  flex: 1 41%;
  width: 181px;
  min-height: 123px;
  height: var(--paymentOption-height);
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
}

.paymentCardWrapper {
  display: flex;
  justify-content: space-evenly;
  > div:first-child {
    margin-right: 16px;
  }
}

.paymentCard {
  cursor: pointer;
  width: 100%;
  height: var(--paymentCard-height);
  background: #ffffff;
  border: none;
  // margin: 16px 0 0 0;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  div {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #3b3f43;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    color: #9ba3ab;
    width: 150px;
  }
}

.paymentCardMethod {
  color: #fff;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  > span:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #3b3f43;
  }
}

.arrowBtn {
  color: #7372ff;
  cursor: pointer;
}

.arrowBtnWithBg {
  color: #7372ff;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.paymentMethod {
  color: #fff;
  font-weight: 700;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  svg {
    flex: none;
  }
  > span {
    width: 70%;
  }
}

.optionIcon {
  z-index: 99;
}

.optionIconBackdrop {
  position: absolute;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  left: 20px;
  top: 21px;
  background: #f6f7fa;
  opacity: 0.1;
}

.optionIconBackdropLight {
  position: absolute;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  left: 20px;
  top: 21px;
  background: #f3f3ff;
}

.optionIconBackdropCenter {
  position: absolute;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  left: 35px;
  top: 60px;
  background: #f3f3ff;
}

.comingSoonOption {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  flex-basis: 100%;
  flex: 1 41%;
  width: 181px;
  min-height: 123px;
  height: var(--paymentOption-height);

  background: #fff;
  color: #9ba3ab;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
}

.comingSoonMethod {
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > span:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #9ba3ab;
    width: 90px;
  }
  > span:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #d3b25a;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.modal {
  display: flex;
}

.modalHeader {
  background: #fffcf1;
  padding: 16px 27px;
  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #c9a11a;
  }
}

.modalBody {
  padding: 0;
  margin: 0;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  .infoCard {
    background: #f6f7fa;
    padding: 10px 16px;
    margin-bottom: 24px;
    border-radius: 6px;
    display: flex;
    > div:first-child {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
    }
  }
  .infoItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    > span:first-child {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: #9ba3ab;
      margin-bottom: 5px;
    }
    > span:last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #3b3f43;
    }
  }
}

.modalLeft {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 60%;
}

.modalRight {
  border-left: 1px solid #f0f0f0;
  padding: 24px;
  overflow: hidden;
}

.transactionCard {
  background-color: #f3f3ff;
  border-radius: 8px;
  padding: 16px;
  width: 249px;
  margin: 20px 0;
  &:after {
    content: "";
    position: relative;
    display: block;
    height: 10px;
    bottom: -21px;
    left: -16px;
    right: 0;
    width: 249px;
    background: linear-gradient(
        45deg,
        transparent 33.333%,
        #f3f3ff 33.333%,
        #f3f3ff 66.667%,
        transparent 66.667%
      ),
      linear-gradient(
        -45deg,
        transparent 33.333%,
        #f3f3ff 33.333%,
        #f3f3ff 66.667%,
        transparent 66.667%
      );
    background-size: 8px 20px;
    background-position: 0 -10px;
  }
}

.payee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  > span {
    background-color: #eddfff;
    color: #a97be3;
    padding: 0px 4px;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

.payeeDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #9ba3ab;
  }
}

.transaction {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  margin: 4px 0;
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-weight: bold;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  padding: 8px 0;
}

.summaryText {
  margin-top: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #7372ff;
}

.iconLink {
  display: flex;
  align-items: center;
  margin: 12px 0 0 0;
  > img {
    margin-right: 10px;
  }
  > span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #7372ff;
  }
}

.optionTxtButton {
  margin-right: 5px;
  padding: 0.5px;
  // border: 1px solid #d1d5dd;
  &:hover {
    color: #7372ff;
  }
  &:active {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
  &:focus {
    color: #7372ff;
    background: #f3f3ff;
    border-radius: 4px;
  }
}

.confirmModal {
  text-align: center;
  .confirmModalInfo {
    background: #fffcf1;
    padding: 16px 27px;
    margin-bottom: 17px;
    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #c9a11a;
    }
  }
  .confirmModalCTA {
    display: flex;
    justify-content: space-between;
  }
}
.radioContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 0;
}
.tableRow {
  cursor: pointer;
}
