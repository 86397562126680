.greetingsSection {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    margin: 0.5rem;
    padding: 25px;
    font-size: 1.1rem;
}

.userManagement {
  // margin: 20px;
  margin: 0;

  h1 {
    margin: 4rem 0 1rem;
  }

  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }

  .actionIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    button {
      margin-left: 8px;
    }
  }
}

.inviteButton {
  background: linear-gradient(92.79deg, #7372FF 8.36%, #9772FF 92.75%);
  width: 183.45px;
  height: 44px;
  border-radius: 8px;
  float: right;
}

label {
  margin: 0 !important;
  width: 120px !important;
}

h1 {
  margin: 4rem 0 3rem;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.rolesPermissions {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  // padding: 0.1rem 0rem 0;

  .columnTitles {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 20vw;
  }

  .roleTitle, .permissionsTitle {
    font-weight: bold;
  }

  .separator {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }

  .content {
    display: flex;
    height: 100%;

    .rolesColumn, .permissionsColumn {
      flex: 1;
    }

    .verticalSeparator {
      width: 1px;
      background-color: #ccc;
      margin: 0 20px;
    }

    .rolesColumn {
      .ant-tabs-tabpane {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.content {
  :global(.ant-tabs-nav-list) {
    margin: 0 2rem 0 0 !important;
    width: 20vw !important;
  }
  
  :global(.ant-tabs-ink-bar) {
    width: 100% !important;
    z-index: -1 !important;
    border-radius: 2px !important;
    border: 0.5px solid #7372FF !important;
    background: rgba(246, 247, 250, 0.70) !important;
  }
}
