.container{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: fit-content;
    border-radius: 8px;
    background: #FFFFFFCC;
    margin: 0 10px;

    :global(.ant-switch-handle::before) {
        border-radius: 2px !important;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922) !important;
        border: 1px solid #EEF1F1 !important;
        background: #000000;
        // width: 21px;
        top: -2px;
        transform: scaleX(1.4);
    }
}

.saveBadge {
    /* position: absolute; */
    /* top: 0px; */
    /* left: 0px; */
    background-color: #fff;
    color: #ffffff;
    padding: 5px 10px;
    // border-radius: 5px 0px;
    // font-size: 12px;
    background: black;
    border-radius: 8px 8px 0 0;
    text-align: center;
    width: 100%;
    font-size: 0.95rem;
    font-weight: 600;
    /* z-index: 1; */
}

.subscriptionCard{
    border: 1px solid #EEF1F1;
    position: relative;
    // height: 85vh;
    // height: 560px;
    // height: 100%;
    // width: 357px;
    width: 28%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    // box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.031372549);
    box-shadow: 0px 4px 4px 0px #0000000D;
    // gap: 3vh;
    justify-content: space-between;
    margin: 16px;
    align-items: center;
    border-radius: 8px;
    color: #82898F;
}

.switchContainer {
    width: 100%;
    padding: 2rem 0 1rem;
}

.subscriptionCardHeader{
    // background-color: #7372FF;
    color: #545454;
    width: 100%;
    height: 38%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 15px;
    padding-top: 5px;
}

.planName{
    color: #545454;
    border-bottom: 3px solid #fff;
    padding: 8px 8px 0;
    font-size: 20px;
    margin: 0;
}

.price{
    color: #545454;
    span {
        font-size: 56px
    }
}

.planDescription{
    width: 80%;
    color: #545454;
    text-align: center;
    font-size: 0.8rem;
    margin-top: -10px;
    text-decoration: underline;
    line-height: 1.5;
}

.subDescription {
    text-align: center;
    width: 90%;
}

.cardContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    height: 62%;
    // width: 87%;
    width: 100%;
    // padding-bottom: 18px;
    // padding-top: 18px;
    padding: 15px 0;
    position: relative;
}

.featuresList{
    list-style-type: none;
    margin: 5px 0 0 0;
    padding: 0;
    width: 90%;

    .featureItem {
        display: flex;
        align-items: center;
        margin: 0.2rem 0;
    }

    .featureIcon {
        margin-right: 4px;
        margin-top: 2px;
    }

    li {
        line-height: 24px;
        font-size: 14px;
        color: #82898F;
        font-weight: 400;
    }
}

.addOn {
    margin: 10px 0px 0px;
    width: 90%;
}

.cardFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px
}

.button{
    border-radius: 16px;
    height: 50px !important;
    width: 87%;
    // height: 40px !important;
    background: linear-gradient(95deg, #7372FF 5.16%, #9772FF 85.19%);
    border-width: 0;
    &:hover{
        opacity: 0.6;
    }
    &:active{
        background-color: #7372FF;
    }
    &:disabled{
        background-color: #22e764;
    }
    span {
        font-weight: bold;
    }
}

.selected {
    background: #1DBC83;
    height: 50px !important;
    &:hover{
        background: #1DBC83;
        color: #fff;
        border-color: #1DBC83;
        opacity: 1
    }
    &:disabled {
        background: #1DBC83 !important;
        color: #fff !important;
        border-color: #1DBC83 !important;
    }
    &:disabled:hover {
        background: #1DBC83 !important;
        color: #fff !important;
        border-color: #1DBC83 !important;
    }
}

.taxCard{
    background-color: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.031372549);
    border-radius: 8px;
    color: #82898F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 16px;
    margin: 0;
    padding: 1rem 2.5rem;
    gap: 1rem;
    width: 88%;
}

.taxPara {
    margin: 0;
}

.taxButton{
    border-radius: 16px;
    width: 20%;
    height: 40px !important;
    background: linear-gradient(95deg, #7372FF 5.16%, #9772FF 85.19%);
    border-width: 0;
    &:hover{
        opacity: 0.6;
    }
    &:active{
        background-color: #7372FF;
    }
    span {
        font-weight: bold;
    }
}

.taxContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 0 0 2vw 0px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    /* gap: 10px; */

    p {
        margin: 0;
    }
}

.taxTypeContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}

.switchLink {
    // width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 5vw 2vw 5vw;
    align-items: flex-end;
}
