.collapsePanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3ff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 21px 24px 8px 24px;
    color: #3b3f43;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    > div {
      > div:first-child {
        margin-bottom: 5px;
      }
    }
  }
  &:after {
    content: "";
    position: relative;
    display: block;
    height: 17px;
    bottom: -10px;
    left: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(
        45deg,
        transparent 33.333%,
        #f3f3ff 33.333%,
        #f3f3ff 66.667%,
        transparent 66.667%
      ),
      linear-gradient(
        -45deg,
        transparent 33.333%,
        #f3f3ff 33.333%,
        #f3f3ff 66.667%,
        transparent 66.667%
      );
    background-size: 14px 27px;
    background-position: 0 -10px;
  }
}

.panelHeader {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #82898f;
}
