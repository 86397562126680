.wrapper {
  margin: 0;
  padding: 20px 28px 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --card-height: calc(100vh - var(--header-height) - 35px);
  --success-height: calc(100vh - var(--header-height) - 135px - 40px);
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.body {
  width: 100%;
}

.onboardingCard {
  > div {
    margin: 0;
    padding: 0;
    height: var(--card-height);
    display: flex;
    justify-content: space-between;
  }
}

.onboardingCardLeft {
  background-color: #7372ff;
  width: 50%;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 43px 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  > div {
    > div:first-child {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
      width: 60%;
      margin: 16px 0;
    }
    > div:last-child {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }
}

.onboardingCardRight {
  width: 50%;
  height: 100%;
  padding: 40px 50px;
  overflow-y: auto;
}

.carouselImg {
  width: 50%;
  margin: auto;
}

.formWrapper {
  > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #3b3f43;
  }
}

.nextButton {
  width: 163px;
  height: 44px;
}

.footerButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 32px 0;
}

.industryBtnWrapper {
  margin: 16px 0;
  width: 100%;
}

.industryRadioBtn {
  height: 56px;
  width: 500px;
  color: #3b3f43;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #d1d5dd;
  &:first-child {
    border-left-width: 1px !important;
    border-left-style: solid !important;
    border-left-color: #d1d5dd;
  }
  //   border: 1px solid #d1d5dd;
  //   border-left: 1px solid #d1d5dd !important;
  &:hover {
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  }
  //   &:focus {
  //     border-color: #3f63e2 !important;
  //     border-left-color: #3f63e2 !important;
  //   }
  //   &:active {
  //     border-color: #3f63e2 !important;
  //     border-left-color: #3f63e2 !important;
  //   }
  > span {
    // background-color: #f3f3ff !important;
    border-radius: 6px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  svg {
    display: none;
  }
  input {
    width: 100%;
  }
}

.subheading {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #82898f;
  margin-bottom: 28px;
}

.typeRadioBtn {
  height: 242px;
  max-width: 237px;
  color: #3b3f43;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #d1d5dd;
  border-radius: 8px;
  padding: 20px 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  &:first-child {
    border-left-width: 1px !important;
    border-left-style: solid !important;
    border-left-color: #d1d5dd;
  }
  &:hover {
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  }
  > span {
    // background-color: #f3f3ff !important;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 21px 8px;
  }
  svg {
    display: none;
  }
  input {
    width: 100%;
  }
}

.typeRadioBtnTitle {
  > div:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #3b3f43;
    margin-bottom: 10px;
  }
  > div:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #82898f;
  }
}

.typeRadioBtnImgs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > img {
    width: 82px;
    height: 119px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.mobileInputPrefix {
  > div {
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
  }
}

.success {
  height: var(--success-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.closeIcon {
  text-align: end;
  cursor: pointer;
}

.linkButton {
  padding: 0 0 0 5px;
  margin: 0;
  > span {
    text-decoration: underline;
  }
}

.extra {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #82898f;
}
