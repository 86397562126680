.wrapper {
  margin: 0;
  padding: 28px 28px 28px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.layout {
  width: 100%;
  padding-top: 80px;
}
.bodyWrapper {
  display: flex;
}
.body {
  width: 100%;
}
.arrowBtnWithBg {
  color: #7372ff;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.settingsCard {
  width: 100%;
  height: 81.3vh;
  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: auto;
    height: 100%;
    width: 100%;
  }
  color: #3b3f43;
}
.invoiceSettingsHeading {
  text-align: left;
  width: 40%;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #3b3f43;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #9ba3ab;
  }
}
.settingsOptionsWrapper {
  width: 40%;
  margin: 50px 0 0 0;
}
.settingsOptionCard {
  height: 78px;
  background: #f3f3ff;
  border-radius: 8px;
  margin-bottom: 24px;
  cursor: pointer;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 24px;
    margin: 0;
    width: 100%;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }
  > div > div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3b3f43;
  }
  > div > div > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #9ba3ab;
  }
}
