.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.connectRow {
  margin: 20px 0;
  gap: 5rem;
  .connectedButton {
    background-color: black;
    border-color: black;
  }
}
  
.subText {
  color: #82898F80;
  margin: 20px 0;
}
  
.accountRow {
  margin-bottom: 15px;
  text-align: left;
  align-items: center;
  .selectBusiness {
    margin: 10px 0;
    width: 100%;
  }
}
  
.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; /* Adjust as needed */
}
      
.connectButton {
  background-color: #008000;
  border-color: #008000;
  color: #fff;
  font-size: 0.9rem;
  width: 140px;
}
  
.continueButton {
  width: 100%;
}
  
.signupFormInputs {
    margin-top: 1rem;
}

.connectRow {
  margin: 20px 0;
  gap: 5rem;
  .connectedButton {
    background: black;
    transition: background 0.3s;
    border-color: black;
    color: white;
    padding: 1rem 3rem;
    border-radius: 8px;
    
    &:hover {
      background: rgba(255, 255, 255, 0.5);
      border-color: rgba(255, 255, 255, 0.5);
      color: black;
    }
  }
}

.connectCard {
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #111111;
  border-radius: 5px;
  padding: 10px;
  height: 90px;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
}
    
.bankConnectedCard {
  position: relative;
  border: 1px solid #000;
  border-radius: 5px;
  // margin: 0 10px 20px 0;
  padding: 20px;
  width: 120px;

  .closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    transition: color 0.3s ease;

    &:hover {
      color: red;
    }
  }

  img {
    max-width: 100px;
    max-height: 50px;
    display: block;
    margin: 0 auto;
  }
}

.subText {
  color: #82898F80;
  margin: 20px 0;
}

.accountRow {
  margin-bottom: 15px;
  text-align: left;
  align-items: center;
  .selectBusiness {
    margin: 10px 0;
    width: 100%;
  }
}

.continueButton {
  width: 100%;
}
