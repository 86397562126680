.statusContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 100px;
}

.status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  position: relative;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 100px;
    z-index: 5; /* 4 */
    top: 8px;
    left: 20px;
    border: 1px solid #000;
  }
}
