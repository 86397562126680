.tag {
  border: none;
  padding: 0 2px;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.wire {
  background-color: #fff5d3;
  color: #c9a11a;
}

.ach {
  background-color: #eddfff;
  color: #a97be3;
}

.card {
  background-color: #f1ffd3;
  color: #9cb861;
}

.check {
  background-color: #ffede3;
  color: #c6a390;
}

.cashback {
  background-color: #ffede3;
  color: #c69990;
}
