.otterzUsageComponentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 0 0 25vh 0;
  box-sizing: border-box;

  .otterzUsageComponent {
    max-width: 400px;
    width: 100%;
    text-align: left;

    .title {
      // Title styles
    }

    .subText {
      display: block;
      margin-bottom: 20px;
    }

    .checkboxGroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;

      .ant-checkbox-wrapper {
        width: 100%;
        margin-bottom: 10px; // Add some space between checkboxes
      }
    }

    .submitButton {
      width: 100%;
    }

    :global(.ant-checkbox-wrapper) {
      width: 100% !important;
      margin: 0.2rem 0 !important;
    }  
  }
}
