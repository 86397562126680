.modal {
  > div > div {
    overflow-y: auto;
  }
}

.transactionCard {
  background-color: #f3f3ff;
  border-radius: 8px;
  padding: 16px;
  width: 249px;
  margin: 20px 0;
  background-color: aqua;
}

.modalLeft {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 50%;
}

.modalRight {
  border-left: 1px solid #f0f0f0;
  padding: 24px;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  width: 50%;
}
.paymentMethod {
  display: inline-flex;
  justify-content: space-between;
  width: 250px;
}
.checkbox {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.confirmContainer {
  margin: auto;
  width: auto;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  > span {
    color: #82898f;
    text-align: center;
    font-size: 12px;
  }
}
.inputAmount {
  > span {
    margin-left: 65px;
    color: black;
    border-right: none;
  }

  > input {
    background-color: #f6f7fa;
    font-size: 24px;
    font-weight: 700;
    border: 1px solid #ffffff;
  }
}

.mobileInputPrefix {
  margin-top: 8px;
  > div {
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  > div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9ba3ab;
  }
}

.accountNumberField iframe {
  width: 100% !important;
}

.cardNumberField {
  width: 100% !important;
}

.hasFile {
  margin-bottom: 0 !important;

  & > :global(.ant-form-item-control) {
    display: none;
  }
}

.hideControl {
  display: none;
}

.uploadCard {
  position: relative;
  border: 1px solid #9f9cff;
  height: 120px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  transition: 0.5s;

  > div :last-child {
    width: 150px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #9f9cff;
  }
}

.uploadCard:hover {
  background-color: #edeaec;
  transition: 0.5s;
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 16px;
  color: #9ba3ab;
}
