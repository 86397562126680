.greetingsSection {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    margin: 0.5rem;
    padding: 25px;
    font-size: 1.1rem;
}

.userManagement {
  // margin: 20px;
  overflow-y: auto;
  margin: 0;
  height: 100%;
  
  h1 {
    margin: 4rem 0 1rem;
  }

  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }

  .actionIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    button {
      margin-left: 8px;
    }
  }
}

.inviteButton {
  background: linear-gradient(92.79deg, #7372FF 8.36%, #9772FF 92.75%);
  width: 183.45px;
  height: 44px;
  border-radius: 8px;
  float: right;
}

label {
  margin: 0 !important;
  width: 120px !important;
}

.ant-form-item {
  margin-bottom: 20px !important;
}
