.wrapper {
  margin: 0;
  padding: 20px 28px 20px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  --table-height: calc(100vh - var(--header-height) - 230px);
}

.layout {
  width: 100%;
  padding-top: 80px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
}

.topRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 75px;
}

.body {
  display: flex;
  flex-direction: column;
}

.valueCard {
  height: 137px;
  margin: 0;
  width: 100%;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
  }
  color: #3b3f43;
}

.checksReceived {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 33.3%;
  text-align: right;
  h3 {
    color: #1dbc83;
    width: 108px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #e1fff4;
    color: #1dbc83;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
  }
}

.pendingOutgoingChecks {
  border-left: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 33.3%;
  padding: 0;
  margin: 0;
  text-align: right;
  h3 {
    color: #7372ff;
    width: 108px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  .icon {
    background-color: #f3f3ff;
    color: #7372ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amount {
    > div {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      margin-right: 5px;
    }
  }
}

.optionCard {
  cursor: pointer;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
  }

  width: 269px;
  height: auto;
  margin: 0 0 0 16px;
  background: #7372ff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    z-index: 99;
  }

  .optionIconBackdrop {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left: 35px;
    top: 35px;
    background: #f6f7fa;
    opacity: 0.1;
  }
  .arrowBtnWithBg {
    color: #7372ff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    span {
      width: 150px;
    }
  }
}

.optionCardLight {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:before {
      content: none !important;
    }
    &:after {
      content: none !important;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  width: 269px;
  height: auto;
  margin: 0 0 0 16px;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.03);
  .optionIcon {
    z-index: 99;
  }
  .optionCardComingSoonLabel {
    color: #d3b25a;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 150%;
  }
  .optionIconBackdropLight {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    left: 35px;
    top: 35px;
    background: #f6f7fa;
  }
  .arrowBtnWithBg {
    color: #fff;
    background-color: #e9edf4;
    border-radius: 50%;
    cursor: pointer;
  }
  .cardAction {
    color: #9ba3ab;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      width: 100px;
    }
    > div {
      display: flex;
      flex-direction: column;
    }
  }
}

.tableCard {
  > div {
    padding: 0;
    margin: 0;
  }
  margin: 16px 0;
  width: 100%;
}

.tableCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 5px 24px;
}

.tableCardFilters {
  display: flex;
  padding: 5px 24px 16px 24px;
  text-transform: uppercase;
}

.table {
  height: var(--table-height);
  width: 100%;
  overflow: auto;

  :global {
    .ant-table {
      height: calc(var(--table-height) - 200px) !important;
    }
  }

}
.chequeTable {
  height: var(--table-height);
  width: 100%;
  overflow: auto;
}
